import React from "react";
import "moment/locale/fr";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import TabletPage from "tabletApp/TabletPage";
import "../index.css";

const NotFound = () => <h1>Page not found!</h1>;
const AppRouter = props => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/checkin" component={TabletPage} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default AppRouter;
