import React, { useState, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../redux/";

const mapStateToProps = state => ({
  suppliers: state.supplier.all,
  rooms: state.room.all,
  bookings: state.booking.all
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  dispatch
});

const withChooseStudioPageContainer = Compoennt => {
  function ChooseStudioPageContainer(props) {
    const { getBookingListRequest, history, location } = props;
    const [clubId, setClubId] = useState("");
    const [roomId, setRoomId] = useState("");
    const [sectionId, setSectionId] = useState(0);

    const onClubChange = function onClubChange(nextClub) {
      setRoomId("");
      setClubId(nextClub);
      setSectionId(1);
    };

    const onRoomChange = function onRoomChange(nextRoom) {
      setRoomId(nextRoom);
      getBookingListRequest({ roomId: nextRoom });
      setSectionId(2);
    };

    const onBackSection = function onBackSection() {
      const nextSectionId = sectionId - 1;

      if (nextSectionId === 0) {
        setRoomId("");
        setClubId("");
      }

      if (nextSectionId === 1) {
        onClubChange(clubId);
      }
      setSectionId(nextSectionId);
    };

    const onSelectTime = beginDate => {
      history.push(`/checkin/${clubId}/${roomId}?startTime=${beginDate}`);
    };

    useLayoutEffect(() => {
      const regexClubId = /[?&]clubId(=([^&#]*)|&|#|$)/;
      const regexRoomId = /[?&]roomId(=([^&#]*)|&|#|$)/;

      const parseResultClubId = new RegExp(regexClubId).exec(location.search);
      const parseResultRoomId = new RegExp(regexRoomId).exec(location.search);
      const prevClubId = parseResultClubId && parseInt(parseResultClubId[2], 10);
      const prevRoomId = parseResultRoomId && parseInt(parseResultRoomId[2], 10);

      if (prevClubId) {
        setClubId(prevClubId);
      }

      if (prevRoomId) {
        setRoomId(prevRoomId);
      }

      if (prevClubId && prevRoomId) {
        onRoomChange(prevRoomId);
      }
    }, []);

    const localStateToProps = {
      sectionId,
      roomId,
      clubId,
      onClubChange,
      onRoomChange,
      onBackSection,
      onSelectTime
    };

    return <Compoennt {...props} {...localStateToProps} />;
  }

  return connect(mapStateToProps, mapDispatchToProps)(ChooseStudioPageContainer);
};

export default withChooseStudioPageContainer;
