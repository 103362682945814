import { getCustomerHistory } from "./helper";

export const getCustomerBookingHistory = (config = {}) => {
  const cancelToken = config.params.source.token;
  const params = Object.assign(
    {},
    {
      ...config.params,
      "customers[]": config.params.customerId
    }
  );
  delete params["customerId"];
  delete params["bookingId"];
  delete params["source"];
  const sendRequest = getCustomerHistory({
    params,
    cancelToken
  });
  return sendRequest;
};

export const getCustomerSessionAvailableList = (config = {}) => {
  const cancelToken = config.params.source.token;
  const params = Object.assign({}, config.params);
  delete params["bookingId"];
  delete params["source"];

  const sendRequest = getCustomerHistory({
    params,
    cancelToken,
    mode: "no-cors"
  });
  return sendRequest;
};
