const createConfig = PROCESS_ENV => ({
  apiBaseUrl: PROCESS_ENV.REACT_APP_RESAMANIA_API_BASE_URL,
  episodBaseUrl: PROCESS_ENV.REACT_APP_RESAMANIA_EPISOD_BASE_URL,
  templateGrid: {
    // These templates are all for bootcamp
    "/episod/templates/15": {
      equipments: ["step", "treadmill"]
    },
    "/episod/templates/7": {
      equipments: ["step", "treadmill"]
    },
    "/episod/templates/4": {
      equipments: ["step", "treadmill"]
    },
    "/episod/templates/12": {
      equipments: ["step", "race"]
    },
    "/episod/templates/19": {
      equipments: ["step", "treadmill"]
    }
  }
});

export default createConfig(process.env);
