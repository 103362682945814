import PropTypes from "prop-types";
import React from "react";
import Flex from "app/components/Flex";
import Spinner from "app/components/Spinner";
import IconButton from "app/components/IconButton";
import moment from "moment-timezone";

import classes from "./ChooseStudioSelector.module.css";

const ChooseStudioSelector = ({
  children,
  suppliers,
  rooms,
  onClubChange,
  onRoomChange,
  bookings,
  onBackSection,
  onSelectTime,
  sectionId,
  clubId,
  roomId,
  isLoading
}) => (
  <Flex middle direction="column" className={classes.chooseStudioSelector}>
    {sectionId === 0 && (
      <Flex middle direction="row" center className={classes.chooseStudioSection}>
        {!isLoading && suppliers.length > 0 ? (
          suppliers.map(hub => {
            if (hub.name === "Episod") {
              return null;
            }

            return (
              <span key={`$hub__${hub.id}`} className={classes.item} onClick={() => onClubChange(hub.id)}>
                {hub.name}
              </span>
            );
          })
        ) : (
          <Spinner absolute />
        )}
      </Flex>
    )}

    {sectionId === 1 && (
      <Flex middle direction="column" space="between" className={classes.chooseStudioSectionWrapper}>
        <Flex middle direction="row" center className={classes.chooseStudioSection}>
          {!isLoading && rooms.length > 0 ? (
            rooms.filter(room => room.supplierId === clubId).map(room => (
              <span key={`$room__${room.id}`} className={classes.item} onClick={() => onRoomChange(room.id)}>
                {room.name}
              </span>
            ))
          ) : (
            <Spinner absolute />
          )}
        </Flex>

        <Flex middle direction="column" className={classes.footer}>
          <IconButton onClick={onBackSection} />
          <span className={classes.iconTitle} onClick={onBackSection}>
            Retour aux hubs
          </span>
        </Flex>
      </Flex>
    )}

    {sectionId === 2 && (
      <Flex middle direction="column" space="between" className={classes.chooseStudioSectionWrapper}>
        <Flex middle direction="row" center className={classes.chooseStudioSection}>
          {rooms.length > 0 && bookings ? (
            bookings.filter(booking => roomId === booking.roomIdList[0]).map(session => (
              <span
                key={`$session__${session.beginDate}`}
                className={classes.item}
                onClick={() => onSelectTime(session.beginDate)}
              >
                {moment(session.beginDate)
                  .tz("Europe/Paris")
                  .format("HH:mm")}
              </span>
            ))
          ) : (
            <Spinner absolute />
          )}
        </Flex>

        <Flex middle direction="column" className={classes.footer}>
          <IconButton onClick={onBackSection} />
          <span className={classes.iconTitle} onClick={onBackSection}>
            Retour aux hubs
          </span>
        </Flex>
      </Flex>
    )}
  </Flex>
);

ChooseStudioSelector.propTypes = {
  children: PropTypes.node
};

export default ChooseStudioSelector;
