import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
//import MobileDetect from "mobile-detect";
import { unregister } from "./registerServiceWorker";

import App from "app";
import TabletApp from "tabletApp";
import { actions } from "./redux/";
import "./index.css";

function NoMatch() {
  return <h1>Page not found!</h1>;
}

fetch(process.env.REACT_APP_AUTHORIZED_API_BASE_URL)
  .then(response => response.json())
  .then((jsonData) => {
    // jsonData is parsed json object received from url
    console.log(jsonData);

    if(jsonData.authorized === true){
      //const md = new MobileDetect(window.navigator.userAgent);
      //const isTablet = !!md.tablet();
      const isTablet = true;

      const renderApp = (content, domElement, store) => {
        ReactDOM.render(<Provider store={store}>{content}</Provider>, domElement);
      };

      if (!isTablet) {
        const store = require("redux/createStore").default();
        store.dispatch(actions.appReady());
        renderApp(<App />, document.getElementById("root"), store);
        unregister();

        if (module.hot) {
          module.hot.accept("app", () => {
            renderApp(<App />, document.getElementById("root"), store);
          });
        }
      } else {
        const store = require("redux/createStore").default();
        renderApp(<TabletApp />, document.getElementById("root"), store);
        unregister();

        if (module.hot) {
          module.hot.accept("tabletApp", () => {
            renderApp(<TabletApp />, document.getElementById("root"), store);
          });
        }
      }

    }else{
      ReactDOM.render(
        <BrowserRouter>
          <NoMatch />
        </BrowserRouter>,
        document.getElementById("root")
      );
    }


  })
  .catch((error) => {
    // handle your errors here
    console.error(error)
  });

