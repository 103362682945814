import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import sortBy from "lodash/sortBy";
import Flex from "app/components/Flex";
import IconButton from "app/components/IconButton";
import Spinner from "app/components/Spinner";
import ItemSidebar from "tabletApp/tabletComponents/ItemSidebar";
import classes from "./Sidebar.module.css";

const supplierNames = {
  "Place de Clichy": "Clichy"
};

const mapStateToProps = state => {
  const allSupplier = state.supplier.all.map(
    supplier =>
      supplierNames[supplier.name] ? Object.assign({}, supplier, { name: supplierNames[supplier.name] }) : supplier
  );

  return {
    allActivity: state.activity.all,
    isLoadingBooking: state.booking.isLoading,
    allSuppliers: sortBy(allSupplier, "name"),
    allRooms: state.room.all
  };
};

const ItemHub = ({ children, onClick, selected }) => {
  return (
    <div
      className={classnames(classes.itemHub, {
        [classes.itemListActive]: selected
      })}
      onClick={onClick}
    >
      <span className={classes.itemListInner}>{children}</span>
    </div>
  );
};

class Sidebar extends React.Component {
  static defaultProps = {
    allSuppliers: [],
    allRooms: []
  };

  state = {}

  static getDerivedStateFromProps(props, state) {
    const { bookings } = props;
    const sizeBookingsColumn = Math.ceil(bookings.length / 6);
    const columns = [];

    for (let i = 0; i < sizeBookingsColumn; i++) {
      columns.push(bookings.slice(i * 6, i * 6 + 6));
    }
    return {
      columns
    };
  }

  handleClickHub = supplierId => {
    const { setSelectedHub } = this.props;
    setSelectedHub(supplierId);
  };

  renderItem = booking => {
    const { allRooms, allActivity, selectedBooking, handleClickBooking } = this.props;
    const activity = allActivity.find(activity => activity.id === booking.activityIdList[0]);
    const room = allRooms.find(room => room.id === booking.roomIdList[0]);
    return (
      <ItemSidebar
        key={booking.id}
        activity={activity}
        room={room}
        booking={booking}
        selected={booking.id === selectedBooking}
        onClick={() => handleClickBooking(booking)}
      />
    );
  };

  render() {
    const { handleToggleSidebar, allSuppliers, selectedHub, bookings } = this.props;
    const isEmpty = bookings.length === 0;

    const filterSupplier = allSuppliers.filter(supplier => supplier.id !== 6);

    return (
      <div className={classes.root}>
        <Flex direction="column" size={2}>
          {filterSupplier.map(supplier => (
            <ItemHub
              key={supplier.name}
              onClick={() => this.handleClickHub(supplier.id)}
              selected={selectedHub && selectedHub === supplier.id}
            >
              <span className={classes.itemListHub}>{supplier.name}</span>
            </ItemHub>
          ))}
        </Flex>
        {isEmpty ? (
          <Flex direction="row" size={6} className={classes.loading}>
            <Spinner />
          </Flex>
        ) : (
          <Flex direction="row" size={6} className={classes.list}>
            {this.state.columns.map((bookings, index) => (
              <Flex
                key={`sidebar__booking_column_${selectedHub}_${index}`}
                direction="column"
                className={classes.column}
              >
                {bookings.map(this.renderItem)}
              </Flex>
            ))}
          </Flex>
        )}
        <Flex direction="column" size={1} end center>
          <IconButton size={1.5} onClick={() => handleToggleSidebar()} />
        </Flex>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(Sidebar);
