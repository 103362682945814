import React from "react";
import classnames from "classnames";
import LongPress from "react-long";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { actions } from "../../../redux";
import classes from "./ItemParticipant.module.css";
import { getDecoratedPosition, getParticipantPicture, getBootCampEuipmentName, getPigalleAthleticPosition, getRowingEquipmentName } from "../../helper";
//import { ReactComponent as Neoness } from "./Neoness.svg";
import { ReactComponent as Episod } from "./Abonnement.svg";
import { ReactComponent as Gymlib } from "./Gymlib.svg";
import { ReactComponent as Classpass } from "./Classpass.svg";

const mapStateToProps = (state, ownProps) => {
  const customerHistoryAtBooking = state.customerHistory.all[ownProps.booking.id];
  return {
    customerHistory: customerHistoryAtBooking ? customerHistoryAtBooking[ownProps.participant.customerId] : {},
    sessionHistory: state.customerHistory.sessionHistory[ownProps.participant.customerId]
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  dispatch
});

const ItemParticipant = ({
  index,
  participant,
  onLongPress,
  onPress,
  customerHistory,
  sessionHistory,
  contactTypes,
  getCustomerSessionAvailableListRequest,
  booking
}) => {
  const name = participant.customerInformation.surname.toLowerCase();
  const src = getParticipantPicture(participant.customerInformation.storedFileId);

  const bootcampEquipment = participant.personalEquipmentInformation
    ? getBootCampEuipmentName(participant.personalEquipmentInformation.equipmentId)
    : "";

  const rowingEquipment = participant.personalEquipmentInformation
    ? getRowingEquipmentName(participant.personalEquipmentInformation.equipmentId)
    : "";

  var position = "";
  var placement = "";

  if (participant.booking.templateKey === "/episod/templates/23") {
    position = participant.personalEquipmentInformation
    ? getPigalleAthleticPosition(participant.personalEquipmentInformation.equipmentName)
      : index + 1;
  }
  else {
    position = participant.personalEquipmentInformation
      ? getDecoratedPosition(participant.personalEquipmentInformation.equipmentName)
      : index + 1;
  }

  if (participant.booking.templateKey === "/episod/templates/28"){
    placement = rowingEquipment + position;
  }
  else{

    if (bootcampEquipment){
      placement = bootcampEquipment + position;
    }
    else{
      placement = position;
    }

  }

  // let totalAvailableSessions = 0;
  // let hasEpisodSessions = false;
  // let customerHistoryFiltered;
  // if (customerHistory) {
  //   customerHistoryFiltered = getHistoryFiltered(customerHistory, booking);
  // }

  // if (sessionHistory) {
  //   const validEpisodSessions = sessionHistory.filter(session => {
  //     return (
  //       session.typeofaboId === 2 &&
  //       moment(session.begindate).isBefore(moment()) &&
  //       moment(session.enddate).isAfter(moment())
  //     );
  //   });

  //   if (validEpisodSessions.length > 0) {
  //     hasEpisodSessions = true;
  //   }

  //   if (hasEpisodSessions) {
  //     totalAvailableSessions = validEpisodSessions.reduce((acc, cur) => {
  //       return acc + cur.total - cur.used;
  //     }, 0);
  //   }
  // }

  const isFirstTimeEpisod = customerHistory && customerHistory.total;
  const isBirthday = moment(participant.customerInformation.borndate).format("MMDD") === moment().format("MMDD");

  return (
    <LongPress time={1000} onLongPress={onLongPress} onPress={onPress}>
      <div
        className={classnames(classes.root, {
          [classes.checked]: participant.present,
          [classes.first]: true
        })}
      >
        {participant.subscriptionInformationList
          .filter(
            subscription => !subscription.disable
          )
          .map(subscription => {
            //console.log(subscription);
            if (subscription.typeofaboId === 4 || subscription.typeofaboId === 6 || subscription.typeofaboId === 12 || subscription.typeofaboId === 13 || subscription.typeofaboId === 18 || subscription.typeofaboId === 19 || subscription.typeofaboId === 22 || subscription.typeofaboId === 27 || subscription.typeofaboId === 28 || subscription.typeofaboId === 29 || subscription.typeofaboId === 29 || subscription.typeofaboId === 30 || subscription.typeofaboId === 32 || subscription.typeofaboId === 33 || subscription.typeofaboId === 35 || subscription.typeofaboId === 38) {
              return (
                <span key="badge-episod" className={classes.icon}>
                  <Episod width={24} height={24} />
                </span>
              );
            }

            if (subscription.typeofaboId === 10 && subscription.cost === 1) {
              return (
                <span key="badge-gymlib" className={classes.icon} data-type="gymlib">
                  <Gymlib width={24} height={24} />
                </span>
              );
            }

            if (subscription.typeofaboId === 23 && subscription.cost === 1) {
              return (
                <span key="badge-classpass" className={classes.icon} data-type="classpass">
                  <Classpass width={24} height={24} />
                </span>
              );
            }

            return null;
          })}


        {participant.customerInformation.storedFileId ? <img src={src} className={classes.img} alt="user-img" /> : null}
        {isBirthday && (
          <div className={classes.iconBirthday}>
            <span role="img" aria-label="emoji-birthday">
              🎂
            </span>
          </div>
        )}
        <div className={classes.name}>{name}</div>
        <div className={classes.position}>{placement}</div>
        <div className={classes.bars}>{isFirstTimeEpisod && <div className={classes.barFirst} />}</div>
      </div>
    </LongPress>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemParticipant);
