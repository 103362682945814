import axios from "axios";
import paramsSerializer from "./paramsSerializer";

let client;
let customerHistoryClient;

function createHttpClient() {
  if (!client) {
    // create axios client with custom params serializer
    client = axios.create({
      baseURL: process.env.REACT_APP_RESAMANIA_API_BASE_URL,
      paramsSerializer
    });
  }

  return client;
}

export function createCustomerHistoryClient() {
  if (!customerHistoryClient) {
    // create axios client with custom params serializer
    customerHistoryClient = axios.create({
      baseURL: process.env.REACT_APP_CUSTOMER_API_BASE_URL,
      paramsSerializer
    });
  }

  return customerHistoryClient;
}

export default createHttpClient;
