import createHttpClient, { createCustomerHistoryClient } from "http-client/axios/createHttpClient";
const httpClient = createHttpClient();
const customerHistoryClient = createCustomerHistoryClient();
export const get = function get(url, config) {
  return httpClient
    .get(url, config)
    .then(response => ({
      response
    }))
    .catch(error => ({
      error
    }));
};

export const post = function post(url, config) {
  return httpClient
    .post(url, config)
    .then(response => ({
      response
    }))
    .catch(error => ({
      error
    }));
};

export const put = function put(url, config) {
  return httpClient
    .put(url, config)
    .then(response => ({
      response
    }))
    .catch(error => {
      // Catch and return internal server error
      // Axios handle error object in strange way when 500
      if (error.message.match(/500/)) {
        return {
          error: {
            response: {
              status: 500
            }
          }
        };
      }

      return {
        error
      };
    });
};

export const getCustomerHistory = function(config) {
  return customerHistoryClient
    .get("", config)
    .then(response => ({
      response
    }))
    .catch(error => ({
      error
    }));
};
