import moment from "moment-timezone";

export const getPosition = equipmentName => {
  if (equipmentName) {
    const result = equipmentName.match(/\d+/);
    if (result) {
      return result[0];
    }
  }

  return "";
};

export const getDecoratedPosition = equipmentName => {
  if (equipmentName) {
    const position = getPosition(equipmentName);
    if (position < 10) {
      return "0" + position;
    }

    return position;
  }
};

export const getBootCampEuipmentName = equipmentId => {
  const regStep = /(step|Step)/;
  const regRace = /race/;
  const regTreadmill = /(treadmill|Tapis)/;

  if (!equipmentId) {
    return null;
  }

  if (equipmentId.match(regStep)) {
    return "F";
  }

  if (equipmentId.match(regRace)) {
    return "T";
  }

  if (equipmentId.match(regTreadmill)) {
    return "T";
  }

  return null;
};

export const getRowingEquipmentName = equipmentId => {
  const regStep = /(step|Step)/;
  const regRameur = /square/;

  if (!equipmentId) {
    return null;
  }

  if (equipmentId.match(regStep)) {
    return "T";
  }

  if (equipmentId.match(regRameur)) {
    return "R";
  }

  return null;
};

export const getPigalleAthleticPosition = equipmentName => {
  if (equipmentName) {
    const position = getPosition(equipmentName);

    let placeName = position;

    if (position.length >= 2) {
      const secondCharacter = position[1];

      switch (secondCharacter) {
        case '0':
          placeName = placeName.replace(secondCharacter, 'A');
          break;
        case '1':
          placeName = placeName.replace(secondCharacter, 'B');
          break;
        case '2':
          placeName = placeName.replace(secondCharacter, 'C');
          break;
        case '3':
          placeName = placeName.replace(secondCharacter, 'D');
          break;
        case '4':
          placeName = placeName.replace(secondCharacter, 'E');
          break;
        case '5':
          placeName = placeName.replace(secondCharacter, 'F');
          break;
        case '6':
          placeName = placeName.replace(secondCharacter, 'G');
          break;
        case '7':
          placeName = placeName.replace(secondCharacter, 'H');
          break;
        default:
          break;
      }
    }

    return placeName;
  }
};

export const getParticipantPicture = fildeId => {
  return `${process.env.REACT_APP_RESAMANIA_EPISOD_BASE_URL}/upload/StoredFileDownload?id=${fildeId}`;
};

const regMixMatch = /Mix&Match /;
const regWorkShop = /Workshop /;
const regUltimate = /Ultimate /;
const regMaster = /MasterClass/;

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

const shortenActivityName = (name, end = 3, upperCase = true) =>
  upperCase ? name.slice(0, end).toUpperCase() : capitalize(name.slice(0, end));

const sliceActivityName = (name, upperCase = true) =>
  upperCase ? name.split(" ")[0].toUpperCase() : capitalize(name.split(" ")[0]);

export const getActivityName = (activity, { fullName = false } = {}) => {
  if (activity.match(regMaster)) {
    return "M";
  }

  if (activity.match(regMixMatch)) {
    const activities = activity.replace(regMixMatch, "");
    const activityNames = activities.split("&");
    return `MM ${shortenActivityName(activityNames[0])}${shortenActivityName(activityNames[1])}`;
  }

  if (activity.match(regUltimate)) {
    const name = activity.replace(regUltimate, "");
    return `ULT ${fullName ? sliceActivityName(name, false) : shortenActivityName(name)}`;
  }

  if (activity.match(regWorkShop)) {
    const name = activity.replace(regWorkShop, "");
    return `W ${fullName ? sliceActivityName(name, false) : shortenActivityName(name)}`;
  }

  return fullName ? sliceActivityName(activity) : shortenActivityName(activity);
};

/*
If sport then name = first three letters of the sport
If mixandmatch then name ="MM"+SPACE+ first three letters of the sport 1 + first three letters of the sport 2
If workshop then name ="W"+first three letters of the sport
If masterclass then name ="M"
*/

export const getIsCheckinEndingSoon = beginDate => {
  const fourMinBeginDate = moment(beginDate).subtract(4, "minutes");
  const isCheckinEndingSoon = moment().isBetween(fourMinBeginDate, beginDate);

  return isCheckinEndingSoon;
};

export const getHistoryFiltered = (customerHistory, booking) => {
  return customerHistory.filter(
    history => history.present && !history.cancellationDate && history.booking.id !== booking.id
  );
};

export const getIsActivityAlready = (customerHistory, booking) => {
  return customerHistory.some(history => history.booking.activityIdList[0] === booking.activityIdList[0]);
};

export const getSupplierIdFromRoomId = (roomId, allRoom) => {
  if (!roomId) {
    return -1;
  }
  const room = allRoom.find(room => room.id === roomId);

  if (!room) {
    return -1;
  }

  return room.supplierId;
};

export const getIsHubAlready = (customerHistory, booking, allRoom) => {
  return customerHistory.some(history => {
    return (
      getSupplierIdFromRoomId(history.booking.roomIdList[0], allRoom) ===
      getSupplierIdFromRoomId(booking.roomIdList[0], allRoom)
    );
  });
};
