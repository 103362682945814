import React from "react";
// import classnames from "classnames";
import moment from "moment";
import { connect } from "react-redux";
import classnames from "classnames";

import classes from "./ModalParticipant.module.css";
import Flex from "app/components/Flex";
import IconButton from "app/components/IconButton";
import { ReactComponent as Neoness } from "./Neoness.svg";
import { ReactComponent as Episod } from "./Abonnement.svg";
import {
  getDecoratedPosition,
  getParticipantPicture,
  getBootCampEuipmentName,
  getHistoryFiltered,
  getIsActivityAlready,
  getIsHubAlready
} from "../../helper";

/**
 * getHistoryFiltered
 *
 * getHistoryFiltered filtered valid customer history.
 * 1: !history.cancellationDate checks if it cancelled or not.
 *
 * 2: history.booking.id !== booking.id checks if the booking is displayed booking or not.
 * If it is displayed booking, we don't want to include it because we want to know customers past booking at this point.
 * @param {Array<Booking>} customerHistory
 * @param {Booking} booking
 */

const getCustomerJoinedMonth = customerHistory => {
  const oldestCustomerHistory = customerHistory[customerHistory.length - 1];
  if (!oldestCustomerHistory) {
    return 0;
  }

  const beginDate = oldestCustomerHistory.booking.beginDate;
  const threeMonthAgo = moment()
    .subtract("3", "months")
    .startOf("day");

  const twoMonthAgo = moment()
    .subtract("2", "months")
    .startOf("day");

  const oneMonthAgo = moment()
    .subtract("1", "months")
    .startOf("day");

  if (moment(beginDate).isBefore(threeMonthAgo)) {
    return 3;
  } else if (moment(beginDate).isBetween(threeMonthAgo, twoMonthAgo)) {
    return 3;
  } else if (moment(beginDate).isBetween(twoMonthAgo, oneMonthAgo)) {
    return 2;
  } else if (moment(beginDate).isAfter(oneMonthAgo)) {
    return 1;
  }

  return 3;
};

const getHistoryLastThreeMonth = customerHistory => {
  return customerHistory.filter(history =>
    moment(history.booking.beginDate).isBetween(
      moment()
        .subtract("3", "months")
        .startOf("day"),
      moment().endOf("day")
    )
  );
};

const mapStateToProps = (state, ownProps) => {
  const { booking } = ownProps;
  const { activityIdList = [], roomIdList = [] } = booking;
  const customerHistory = state.customerHistory.all[ownProps.participant.customerId];
  const sessionHistory = state.customerHistory.sessionHistory[ownProps.participant.customerId];

  const allSupplier = state.supplier.all;
  const allRoom = state.room.all;
  const allActivity = state.activity.all;
  const room = allRoom.find(room => room.id === roomIdList[0]);
  const activity = allActivity.find(activity => activity.id === activityIdList[0]);
  let isActivityFirst = false;
  let isHubFirst = false;
  let hubName = "";
  let activityName = "";
  let customerHistoryFiltered = [];
  let customerSessionAve = 0;
  let totalAvailableSessions = 0;
  let hasEpisodSessions = false;

  if (customerHistory) {
    customerHistoryFiltered = getHistoryFiltered(customerHistory, booking);
    const customerHistoryLastThreeMonth = getHistoryLastThreeMonth(customerHistoryFiltered);
    const joinedMonth = getCustomerJoinedMonth(customerHistoryLastThreeMonth);
    customerSessionAve =
      customerHistoryLastThreeMonth.length === 0 ? 0 : Math.floor(customerHistoryLastThreeMonth.length / joinedMonth);
  }

  if (room) {
    if (customerHistory) {
      isHubFirst = !getIsHubAlready(customerHistoryFiltered, booking, allRoom);
    }
    const hub = allSupplier.find(supplier => supplier.id === room.supplierId);
    hubName = hub.name;
  }

  if (activity) {
    if (customerHistory) {
      isActivityFirst = !getIsActivityAlready(customerHistoryFiltered, booking);
    }
    activityName = activity.name;
  }

  if (sessionHistory) {
    const validEpisodSessions = sessionHistory.filter(session => {
      return (
        session.typeofaboId === 2 &&
        moment(session.begindate).isBefore(moment()) &&
        moment(session.enddate).isAfter(moment())
      );
    });

    if (validEpisodSessions.length > 0) {
      hasEpisodSessions = true;
    }

    if (hasEpisodSessions) {
      totalAvailableSessions = validEpisodSessions.reduce((acc, cur) => {
        return acc + cur.total - cur.used;
      }, 0);
    }
  }

  return {
    isActivityFirst,
    isHubFirst,
    customerSessionAve,
    customerHistory: customerHistoryFiltered,
    hubName,
    activityName,
    hasEpisodSessions,
    totalAvailableSessions
  };
};

const ModalParticipant = ({
  contactTypes,
  participant,
  onClick,
  isHubFirst,
  isActivityFirst,
  customerSessionAve,
  hubName,
  activityName,
  totalAvailableSessions,
  hasEpisodSessions
}) => {
  const position = participant.personalEquipmentInformation
    ? getDecoratedPosition(participant.personalEquipmentInformation.equipmentName)
    : participant.index + 1;
  const bootcampEquipment = participant.personalEquipmentInformation
    ? getBootCampEuipmentName(participant.personalEquipmentInformation.equipmentId)
    : "";

  return (
    <div className={classes.modal} onClick={onClick}>
      <div className={classes.panel} key={`participant_modal__${participant.id}`}>
        <Flex direction="column" className={classes.panelInner}>
          <Flex direction="row" size="2" className={classes.firstRow}>
            <Flex direction="row" size="1" className={classes.wrapperImg}>
              {participant.customerInformation.storedFileId ? (
                <img
                  alt="user-img"
                  src={getParticipantPicture(participant.customerInformation.storedFileId)}
                  className={classes.img}
                />
              ) : null}
            </Flex>
            <Flex direction="column" size="1">
              <ul className={classes.userInfoList}>
                <li>
                  <span>{participant.customerInformation.surname}</span>
                </li>
                <li>
                  <span>{participant.customerInformation.name.toUpperCase()}</span>
                </li>
                <li>
                  <span>{participant.present ? "Présent" : "Absent"}</span>
                  <span>{bootcampEquipment ? `${bootcampEquipment}${position}` : position}</span>
                </li>
                <li>
                  <span>{customerSessionAve} sessions / mois</span>
                </li>
                <li>
                  <span>{isHubFirst ? `Première fois à ${hubName.slice(0, 3).toUpperCase()}` : ""}</span>
                </li>
                <li>
                  <span>{isActivityFirst ? `Première fois en ${activityName.slice(0, 3).toUpperCase()}` : ""}</span>
                </li>
                <li className={classes.subscription}>
                  {participant.subscriptionInformationList
                    .filter(
                      subscription =>
                        !subscription.disable && moment(new Date()).isSameOrAfter(moment(subscription.begindate))
                    )
                    .map(subscription => {
                      if (subscription.typeofaboId === 4) {
                        return (
                          <span className={classes.icon}>
                            <Episod width={24} height={24} />
                          </span>
                        );
                      }

                      return null;
                    })}
                  {participant.customerInformation.contacttypeId &&
                    contactTypes.find(
                      contactType => contactType.id === participant.customerInformation.contacttypeId
                    ) &&
                    contactTypes.find(contactType => contactType.id === participant.customerInformation.contacttypeId)
                      .name === "Neoness" && (
                      <span className={classes.icon}>
                        <Neoness width={24} height={24} />
                      </span>
                    )}
                  {hasEpisodSessions &&
                    totalAvailableSessions < 4 && <span className={classnames(classes.icon, classes.iconSession)} />}
                </li>
              </ul>
            </Flex>
          </Flex>
          <Flex direction="row" size="1" center middle>
            <IconButton size={1.2} type="arrowdown" />
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(ModalParticipant);
