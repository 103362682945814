import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../redux/";

function withContainer(Component) {
  class ParticipantItemContainer extends React.PureComponent {
    state = { isOpen: false, isFirstTimeEpisod: false };

    onClickParticipantItem = () => {
      const { participant } = this.props;
      if (participant) {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
    };

    onClickCheckinButton = event => {
      event.stopPropagation();
      const { participant, putMarkCustomerPresenceRequest, booking: { id: bookingId } } = this.props;
      putMarkCustomerPresenceRequest({
        bookingId,
        customerId: participant.customerId,
        value: !participant.present
      });
      this.setState({
        isOpen: false
      });
    };

    render() {
      return (
        <Component
          onClickCheckinButton={this.onClickCheckinButton}
          onClickParticipantItem={this.onClickParticipantItem}
          {...this.state}
          {...this.props}
        />
      );
    }
  }

  const mapStateToProps = state => {
    return {};
  };

  const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(actions, dispatch),
    dispatch
  });

  const withParticipantItemContainer = connect(mapStateToProps, mapDispatchToProps);

  ParticipantItemContainer.propTypes = {
    onParticipantCheck: PropTypes.func.isRequired,
    position: PropTypes.number.isRequired,
    participant: ImmutablePropTypes.map.isRequired,
    contactType: ImmutablePropTypes.map.isRequired,
    isFirstTime: PropTypes.bool
  };

  ParticipantItemContainer.defaultProps = {
    isFirstTime: false
  };

  return withParticipantItemContainer(ParticipantItemContainer);
}

export default withContainer;
