import { get, put } from "./helper";
// booking
export const getBookingList = (config = {}) => {
  const sendRequest = get("booking", config);
  return sendRequest;
};

// booking complete participant
export const getBookingCompleteParticipantList = (config = {}) => {
  const bookingId = config.params.bookingId;
  const params = Object.assign({}, config.params);
  delete params["bookingId"];
  const sendRequest = get(`booking/${bookingId}/completeParticipant`, { params });
  return sendRequest;
};

export const putMarkCustomerPresence = (config = {}) => {
  const { bookingId, customerId, value } = config.params;
  const sendRequest = put(`booking/${bookingId}/participant/${customerId}/presence/${value}`);
  return sendRequest;
};
