import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ImmutablePropTypes from "react-immutable-proptypes";
import moment from "moment";
import Flex from "app/components/Flex";
import IconButton from "app/components/IconButton";
import withParticipantItemContainer from "app/containers/ParticipantItemContainer";
import classes from "./ParticipantItem.module.css";
//import Neoness from "./Neoness.svg";
import Abonnement from "./Abonnement.svg";
import Gymlib from "./Gymlib.svg";

const getParticipantPicture = fildeId => {
  return `${process.env.REACT_APP_RESAMANIA_EPISOD_BASE_URL}/upload/StoredFileDownload?id=${fildeId}`;
};

const getClassNameRow = numberOfRows => {
  return classes[`participantItem__row${numberOfRows}`];
};

const today = moment()
  .startOf("day")
  .format();

class ParticipantItem extends React.Component {
  render() {
    const {
      participant,
      position,
      //contactType,
      numberOfRows,
      isFirstTimeEpisod,
      onClickParticipantItem,
      onClickCheckinButton,
      isOpen
    } = this.props;
    const EPISOD_ABO_ID = 4;
    const EPISOD_GYMLIB_ID = 10;

    /*const positionEquipement = participant.personalEquipmentInformation
    ? getDecoratedPosition(participant.personalEquipmentInformation.equipmentName)
    : index + 1;
    const bootcampEquipment = participant.personalEquipmentInformation
    ? getBootCampEuipmentName(participant.personalEquipmentInformation.equipmentId)
    : "";*/

    //console.log(participant);
    //console.log(position);

    const isSubscriptionPresent =
      participant &&
      participant.subscriptionInformationList &&
      participant.subscriptionInformationList.find(subscription => {
        const isAboIdMatched = subscription.typeofaboId === EPISOD_ABO_ID;
        const isSubscriptionBetweenDate = subscription.begindate < today && subscription.enddate > today;
        const isSubscriptionWillNotEnd = subscription.begindate < today && !subscription.enddate;
        const isDisable = subscription.disable;

        return (isSubscriptionBetweenDate || isSubscriptionWillNotEnd) && !isDisable && isAboIdMatched;
      });

    const isSubscriptionGymlibPresent =
      participant &&
      participant.subscriptionInformationList &&
      participant.subscriptionInformationList.find(subscription => {
        const isGymlibIdMatched = subscription.typeofaboId === EPISOD_GYMLIB_ID;
        const isSubscriptionBetweenDate = subscription.begindate < today && subscription.enddate > today;
        const isSubscriptionWillNotEnd = subscription.begindate < today && !subscription.enddate;
        const isDisable = subscription.disable;

        return (isSubscriptionBetweenDate || isSubscriptionWillNotEnd) && !isDisable && isGymlibIdMatched;
      });

    const isBirthday =
      participant &&
      participant.customerInformation &&
      participant.customerInformation.borndate &&
      moment(participant.customerInformation.borndate).format("MMDD") === moment().format("MMDD");

    const isCheckin = participant && participant.present;

    if (!participant || !participant.customerInformation) {


      return (
        <Flex
          direction="column"
          className={classnames(classes.participantItem, getClassNameRow(numberOfRows))}
          grow={1}
        >
          <Flex direction="column" strech size={1} className={classes.wrapper}>
            <div className={classes.participantItem__picture} />
          </Flex>

          <div className={classes.participantItem__position}>{position < 10 ? `0${position}` : position}</div>
        </Flex>
      );
    }

    return (
      <Flex
        direction="column"
        className={classnames(classes.participantItem, getClassNameRow(numberOfRows), {
          [classes.checked]: participant.present
        })}
        grow={1}
      >
        <Flex direction="column" strech size={1} className={classes.wrapper}>
          <div className={classes.participantItem__picture} onClick={onClickParticipantItem.bind(this)}>
            {isOpen && (
              <div className={classes.participantItem__overlay} onClick={onClickParticipantItem.bind(this)}>
                <Flex direction="column" space="between" className={classes.participantItem__overlay__inner}>
                  <Flex direction="column">
                    {participant.customerInformation.surname && (
                      <Flex direction="column" className={classes.participantItem__overlay__sectionName}>
                        <span className={classes.participantItem__overlay__name}>
                          {participant.customerInformation.surname}
                        </span>
                        <span className={classes.participantItem__overlay__name}>
                          {participant.customerInformation.name}
                        </span>
                      </Flex>
                    )}
                    <Flex direction="row">
                      {isSubscriptionPresent && (
                        <span>
                          <img
                            src={Abonnement}
                            alt="icon_abonnement"
                            className={classes.participantItem__overlay__iconImg}
                          />
                        </span>
                      )}
                      {isSubscriptionGymlibPresent && (
                        <span>
                          <img
                            src={Gymlib}
                            alt="icon_gymlib"
                            className={classes.participantItem__overlay__iconImg}
                          />
                        </span>
                      )}
                    </Flex>
                  </Flex>
                  <Flex direction="row" center className={classes.participantItem__overlay__secitonButton}>
                    <IconButton
                      type={participant.present ? "Close" : "Check"}
                      size={1}
                      onClick={onClickCheckinButton.bind(this)}
                    />
                  </Flex>
                </Flex>
              </div>
            )}
            <div className={classes.participantItem__statusBars}>
              {isCheckin && <div className={classes.participantItem__barPresence} />}
              {isFirstTimeEpisod && <div className={classes.participantItem__barFirstTime} />}
            </div>
            {participant.customerInformation.storedFileId && (
              <img
                className={classes.participantItem__img}
                src={getParticipantPicture(participant.customerInformation.storedFileId)}
                alt={`${participant.customerInformation.name}`}
              />
            )}
            {isBirthday && (
              <div className={classes.participantItem__iconBirthday}>
                <span role="img" aria-label="emoji-birthday">
                  🎂
                </span>

              </div>
            )}
          </div>

        </Flex>

        {!isOpen &&
          participant.customerInformation.surname && (
            <div className={classes.participantItem__name}>{participant.customerInformation.surname}</div>
          )}
        {!isOpen && (
          <div className={classes.participantItem__position}>{position < 10 ? `0${position}` : position}</div>
        )}
      </Flex>
    );
  }
}

ParticipantItem.propTypes = {
  onParticipantCheck: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  participant: ImmutablePropTypes.map.isRequired,
  contactType: ImmutablePropTypes.map.isRequired,
  isFirstTimeEpisod: PropTypes.bool
};

ParticipantItem.defaultProps = {
  isFirstTimeEpisod: false
};

export default withParticipantItemContainer(ParticipantItem);
