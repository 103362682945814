import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Layout from "app/components/Layout";
import ChooseStudioSelector from "app/components/ChooseStudioSelector";
import ChooseStudioHeader from "app/components/ChooseStudioHeader";
import withChooseStudioPageContainer from "./ChooseStudioPageContainer";

const ChooseStudioPage = ({
  isLoading,
  suppliers,
  rooms,
  clubId,
  roomId,
  sectionId,
  bookings,
  onClubChange,
  onRoomChange,
  onSelectTime,
  onBackSection
}) => (
  <Layout background>
    <ChooseStudioHeader
      isLoading={isLoading}
      selectedHub={suppliers && suppliers.find(supplier => parseInt(supplier.id, 10) === parseInt(clubId, 10))}
      selectedStudio={rooms && rooms.find(room => parseInt(room.id, 10) === parseInt(roomId, 10))}
    />
    <ChooseStudioSelector
      sectionId={sectionId}
      suppliers={suppliers}
      rooms={rooms}
      roomId={roomId}
      clubId={clubId}
      onClubChange={onClubChange}
      onRoomChange={onRoomChange}
      onBackSection={onBackSection}
      onSelectTime={onSelectTime}
      isLoading={isLoading}
      bookings={bookings}
    />
  </Layout>
);

ChooseStudioPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  sectionId: PropTypes.number.isRequired,
  clubId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  roomId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rooms: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  suppliers: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  sessions: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  onClubChange: PropTypes.func.isRequired,
  onRoomChange: PropTypes.func.isRequired,
  onBackSection: PropTypes.func.isRequired,
  onSelectTime: PropTypes.func.isRequired
};

export default withChooseStudioPageContainer(ChooseStudioPage);
