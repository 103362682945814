import React from "react";
import classnames from "classnames";
import moment from "moment-timezone";
import classes from "./ItemSidebar.module.css";
import { getActivityName, getIsCheckinEndingSoon } from "../../helper";

class ItemSidebar extends React.Component {
  static defaultProps = {
    activity: "",
    coach: "",
    participants: [],
    numberCheckedinParticipants: 0,
    selected: false
  };

  state = {
    initNumber: 0,
    isCheckinEndingSoon: false
  };

  checkSessionTimer = null;

  componentDidMount() {
    this.checkIsCheckinEndingSoon();
    this.checkSessionTimer = setInterval(() => {
      this.checkIsCheckinEndingSoon();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.checkSessionTimer);
  }

  checkIsCheckinEndingSoon = () => {
    const { booking } = this.props;
    const { beginDate } = booking;
    const isCheckinEndingSoon = getIsCheckinEndingSoon(beginDate);
    this.setState({
      isCheckinEndingSoon: isCheckinEndingSoon
    });
  };

  render() {
    const { onClick, selected, activity, booking } = this.props;
    return (
      <div
        className={classnames(classes.root, {
          [classes.selected]: selected
        })}
        onClick={onClick}
      >
        <span className={classes.inner}>
          <span className={classes.name}>{getActivityName(activity.name)}</span>
          <span className={classes.time}>
            {this.state.isCheckinEndingSoon && <span className={classes.iconFire} />}
            {moment(booking.beginDate)
              .tz("Europe/Paris")
              .format("HH:mm")}
          </span>
        </span>
      </div>
    );
  }
}

export default ItemSidebar;
