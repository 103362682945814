const NATION_CLUBID = process.env.NODE_ENV === "production" ? 8 : 7;
const BOURSE_CLUBID = 5;
const PIGALLE_CLUBID = 11;

export const getIsNation = clubId => parseInt(clubId, 10) === NATION_CLUBID;
export const getIsBourse = clubId => parseInt(clubId, 10) === BOURSE_CLUBID;
export const getIsPigalle = clubId => parseInt(clubId, 10) === PIGALLE_CLUBID;


export const getPosition = equipmentName => {
  if (equipmentName) {
    const result = equipmentName.match(/\d+/);
    if (result) {
      return result[0];
    }
  }

  return "";
};

export const getDecoratedPosition = equipmentName => {
  if (equipmentName) {
    const position = getPosition(equipmentName);
    if (position < 10) {
      return "0" + position;
    }

    return position;
  }
};

export const getBootCampEuipmentName = equipmentId => {
  const regStep = /(step|Step)/;
  const regRace = /race/;
  const regTreadmill = /(treadmill|Tapis)/;

  if (!equipmentId) {
    return null;
  }

  if (equipmentId.match(regStep)) {
    return "F";
  }

  if (equipmentId.match(regRace)) {
    return "T";
  }

  if (equipmentId.match(regTreadmill)) {
    return "T";
  }

  return null;
};

export const getRowingEquipmentName = equipmentId => {
  const regStep = /(step|Step)/;
  const regRameur = /square|Square/;


  if (!equipmentId) {
    return null;
  }

  if (equipmentId.match(regStep)) {
    return "T";
  }

  if (equipmentId.match(regRameur)) {
    return "R";
  }

  return null;
};

export const getCurrentTime = bookings => {

  let now = new Date();
  let nowT = now.getTime();
  let startTime = '';
  let last = '';

  bookings.forEach(booking => {

    let bookingBeginDate = new Date(booking.beginDate)
    let bookingBeginDateT = bookingBeginDate.getTime();
    let bookingEndDate = new Date(booking.endDate)
    let bookingEndDateT = bookingEndDate.getTime();

    if(bookingBeginDateT <= nowT && nowT <= bookingEndDateT){
      startTime = booking.beginDate;
    }

    if(nowT < bookingBeginDateT && startTime === ''){
      startTime = booking.beginDate;
    }

    last = booking.beginDate;

  });

  if(startTime === ''){
    return last;
  }

  return startTime;
};
