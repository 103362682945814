import { fork, take, cancel, call, put, all, select, takeEvery, takeLatest } from "redux-saga/effects";
import moment from "moment";
import uniqBy from "lodash/unionBy";

import * as roomApi from "../api/room";
import * as supplierApi from "../api/supplier";
import * as bookingApi from "../api/booking";
import * as coachApi from "../api/coach";
import * as contactApi from "../api/contact";
import * as activityApi from "../api/activity";
import * as customerApi from "../api/customer";

const actionTypeCreator = TYPE => ACTION_TYPE => ({
  request: `@${TYPE}/${ACTION_TYPE}__REQUEST`,
  success: `@${TYPE}/${ACTION_TYPE}__SUCESS`,
  error: `@${TYPE}/${ACTION_TYPE}__ERROR`
});

/***
 * Action Types
 */

const roomActionTypeCreator = actionTypeCreator("ROOM");
const bookingActionTypeCreator = actionTypeCreator("BOOKING");
const contactActionTypeCreator = actionTypeCreator("CONTACT");
const coachActionTypeCreator = actionTypeCreator("COACH");
const supplierActionTypeCreator = actionTypeCreator("SUPPLIER");
const activityActionTypeCreator = actionTypeCreator("ACTIVITY");
const customerTypeCreator = actionTypeCreator("CUSTOMER");

export const actionTypes = {
  getBookingList: bookingActionTypeCreator("GET_BOOKING_LIST"),
  getBookingCompleteParticipantList: bookingActionTypeCreator("GET_COMPLETE_PARTICIPANT"),
  putMarkCustomerPresence: bookingActionTypeCreator("PUT_MARK_CUSTOMER_PRESENCE"),
  getCoachList: coachActionTypeCreator("GET_COACH"),
  getContactTypeList: contactActionTypeCreator("GET_CONTACT_TYPE"),
  getSupplierList: supplierActionTypeCreator("GET_SUPPLIER_LIST"),
  getRoomList: roomActionTypeCreator("GET_ROOM_LIST"),
  getActivityList: activityActionTypeCreator("GET_ACTIVITY_LIST"),
  getCustomerBookingHistory: customerTypeCreator("GET_CUSTOMER_BOOKING_HISTORY"),
  getCustomerSessionAvailableList: customerTypeCreator("GET_CUSTOMER_SESSION_AVAILABLE_LIST"),
  cancelGetCustomerBookingHistory: customerTypeCreator("CANCEL_GET_CUSTOMER_BOOKING_LIST"),
  cancelGetCustomerSessionAvailableList: customerTypeCreator("CANCEL_GET_CUSTOMER_SESSION_AVAILABLE_LIST")
};

/*****
 * Actions
 */

const request = actionTypes => payload => ({
  type: actionTypes.request,
  payload
});

const getBookingCompleteParticipantListRequest = request(actionTypes.getBookingCompleteParticipantList);
const putMarkCustomerPresenceRequest = request(actionTypes.putMarkCustomerPresence);

const getRoomListRequest = request(actionTypes.getRoomList);
const getCoachListRequest = request(actionTypes.getCoachList);
const getContactTypeListRequest = request(actionTypes.getContactTypeList);
const getActivityListRequest = request(actionTypes.getActivityList);
const getCustomerBookingHistoryRequest = request(actionTypes.getCustomerBookingHistory);
const getBookingListRequest = request(actionTypes.getBookingList);
const getCustomerSessionAvailableListRequest = request(actionTypes.getCustomerSessionAvailableList);
const cancelGetCustomerBookingHistoryRequest = request(actionTypes.cancelGetCustomerBookingHistory);
const cancelGetCustomerSessionAvailableListRequest = request(actionTypes.cancelGetCustomerSessionAvailableList);

const appReady = () => ({
  type: "APP_READY"
});

export const actions = {
  getBookingListRequest,
  getBookingCompleteParticipantListRequest,
  putMarkCustomerPresenceRequest,
  getContactTypeListRequest,
  getCoachListRequest,
  getRoomListRequest,
  getActivityListRequest,
  getCustomerBookingHistoryRequest,
  getCustomerSessionAvailableListRequest,
  cancelGetCustomerBookingHistoryRequest,
  cancelGetCustomerSessionAvailableListRequest,
  appReady
};

/***
 * Sagas
 */

function* apiCall({ api, actions, defaultParams }, params = {}, data = {}) {
  const requestParams = Object.freeze({ ...defaultParams, ...params });
  try {
    const { response, error } = yield call(api, { params: requestParams });
    if (error) {
      yield put({ type: actions.error, payload: { ...error }, requestParams });
    }
    if (response) {
      yield put({ type: actions.success, payload: { ...response }, requestParams });
    }
  } catch (error) {
    console.log("server error");
    yield put({ type: actions.error, payload: { ...error }, requestParams });
  }
}

const getSupplierList = apiCall.bind(null, {
  api: supplierApi.getSupplierList,
  actions: actionTypes.getSupplierList,
  defaultParams: {
    offset: 0,
    size: 100
  }
});

const getRoomList = apiCall.bind(null, {
  api: roomApi.getRoomList,
  actions: actionTypes.getRoomList,
  defaultParams: {
    offset: 0,
    size: 100
  }
});

const getCoachList = apiCall.bind(null, {
  api: coachApi.getCoachList,
  actions: actionTypes.getCoachList,
  defaultParams: {
    offset: 0,
    size: 400
  }
});

const getActivityList = apiCall.bind(null, {
  api: activityApi.getActivityList,
  actions: actionTypes.getActivityList,
  defaultParams: {
    offset: 0,
    size: 200
  }
});

const getContactTypeList = apiCall.bind(null, {
  api: contactApi.getContactTypeList,
  actions: actionTypes.getContactTypeList,
  defaultParams: {
    offset: 0,
    size: 100
  }
});

const getBookingList = apiCall.bind(null, {
  api: bookingApi.getBookingList,
  actions: actionTypes.getBookingList,
  defaultParams: {
    offset: 0,
    size: 100,
    begin: moment()
      .startOf("day")
      .format(),
    end: moment()
      .endOf("day")
      .format()
  }
});

const getBookingCompleteParticipantList = apiCall.bind(null, {
  api: bookingApi.getBookingCompleteParticipantList,
  actions: actionTypes.getBookingCompleteParticipantList,
  defaultParams: {
    loadCustomerRestInformation: true,
    loadSubscriptionInformation: true
  }
});

const getCustomerBookingHistory = apiCall.bind(null, {
  api: customerApi.getCustomerBookingHistory,
  actions: actionTypes.getCustomerBookingHistory
});

const getCustomerSessionAvailableList = apiCall.bind(null, {
  api: customerApi.getCustomerSessionAvailableList,
  actions: actionTypes.getCustomerSessionAvailableList
});

const putMarkCustomerPresence = apiCall.bind(null, {
  api: bookingApi.putMarkCustomerPresence,
  actions: actionTypes.putMarkCustomerPresence
});

function* watch(actionType, apiCall) {
  try {
    yield takeEvery(actionType, function*(action) {
      const params = action.payload ? { ...action.payload } : {};
      yield fork(apiCall, params);
    });
  } catch (err) {
    console.error(err);
  }
}

const getActivityListRequestWatch = watch.bind(null, actionTypes.getActivityList.request, getActivityList);
const getCoachListRequestWatch = watch.bind(null, actionTypes.getCoachList.request, getCoachList);
const getContactTypeListRequestWatch = watch.bind(null, actionTypes.getCoachList.request, getContactTypeList);
const getBookingListRequestWatch = watch.bind(null, actionTypes.getBookingList.request, getBookingList);
const getRoomListRequestWatch = watch.bind(null, actionTypes.getRoomList.request, getRoomList);
const getSupplierListRequestWatch = watch.bind(null, actionTypes.getSupplierList.request, getSupplierList);
const getBookingCompleteParticipantListWatch = watch.bind(
  null,
  actionTypes.getBookingCompleteParticipantList.request,
  getBookingCompleteParticipantList
);

const getCustomerBookingRequestListWatch = watch.bind(
  null,
  actionTypes.getCustomerBookingHistory.request,
  getCustomerBookingHistory
);

const getCustomerSessionAvailableListWatch = watch.bind(
  null,
  actionTypes.getCustomerSessionAvailableList.request,
  getCustomerSessionAvailableList
);

const putMarkCustomerPresenceRequestWatch = function* watch() {
  const tasks = {};

  try {
    yield takeEvery(actionTypes.putMarkCustomerPresence.request, function*(action) {
      const params = action.payload ? { ...action.payload } : {};
      if (tasks[params.customerId]) {
        yield cancel(tasks[params.customerId]);
      }
      tasks[params.customerId] = yield fork(putMarkCustomerPresence, params);
    });
  } catch (err) {
    console.error(err);
  }
};

const putMarkCustomerPresenceErrorWatch = function* watch(actionType, apiCall) {
  try {
    yield takeEvery(actionTypes.putMarkCustomerPresence.error, function*(action) {
      const params = action.requestParams;
      if (action.payload.response.status !== 400) {
        yield put(putMarkCustomerPresenceRequest(params));
      }
    });
  } catch (err) {
    console.error(err);
  }
};

function* getSupplierListSuccessWatch() {
  try {
    yield takeLatest(actionTypes.getSupplierList.success, function*(action) {

      const suppliers = action.payload.data.resultList.filter(supplier => supplier.id !== 6);
      const supplierIds = suppliers.map(supplier => supplier.id);

      yield put(actions.getCoachListRequest({ supplierId: supplierIds }));
      yield put(actions.getContactTypeListRequest({ supplierId: supplierIds }));
      yield put(actions.getRoomListRequest({ supplierId: supplierIds }));
      yield put(actions.getActivityListRequest({ supplierId: supplierIds }));
    });
  } catch (err) {
    console.error(err);
  }
}

function* cancelGetCustomerBookingHistoryWatch() {
  try {
    yield takeLatest(actionTypes.cancelGetCustomerBookingHistory.request, function*(action) {
      const allRequests = yield select(state => state.customerHistory.allRequests);
      allRequests.forEach(request => request.source.cancel("Booking ID Changed so API call cancelled"));
    });
  } catch (err) {
    console.error(err);
  }
}

function* cancelGetCustomerSessionAvailableListWatch() {
  try {
    yield takeLatest(actionTypes.cancelGetCustomerSessionAvailableList.request, function*(action) {
      const sessionHistoryRequests = yield select(state => state.customerHistory.sessionHistoryRequests);
      sessionHistoryRequests.forEach(request => request.source.cancel("Booking ID Changed so API call cancelled"));
    });
  } catch (err) {
    console.error(err);
  }
}

function* initWatch() {
  try {
    yield takeLatest("APP_INIT", function*(action) {
      yield put({ type: actionTypes.getSupplierList.request });
      yield fork(loadInitialState);
    });
  } catch (err) {
    console.error(err);
  }
}

const requiredActionTypes = [
  actionTypes.getContactTypeList,
  actionTypes.getRoomList,
  actionTypes.getActivityList,
  actionTypes.getBookingList,
  actionTypes.getSupplierList,
  actionTypes.getCoachList
];
function* loadInitialState() {
  yield all(requiredActionTypes.map(actionType => take(actionType.success)));
  yield put(actions.appReady());
}

export function* sagas() {
  yield all([
    initWatch(),
    getActivityListRequestWatch(),
    getSupplierListRequestWatch(),
    getSupplierListSuccessWatch(),
    getRoomListRequestWatch(),
    getBookingListRequestWatch(),
    getCoachListRequestWatch(),
    getContactTypeListRequestWatch(),
    getBookingCompleteParticipantListWatch(),
    getCustomerBookingRequestListWatch(),
    getCustomerSessionAvailableListWatch(),
    putMarkCustomerPresenceRequestWatch(),
    putMarkCustomerPresenceErrorWatch(),
    cancelGetCustomerBookingHistoryWatch(),
    cancelGetCustomerSessionAvailableListWatch()
  ]);
}

/****
 * Reducer
 */

const appState = {
  isAppInitializing: false
};

const appReducer = (state = appState, action) => {
  switch (action.type) {
    case "APP_READY": {
      return Object.assign({}, state, {
        isAppInitializing: false
      });
    }

    case "APP_INIT": {
      return Object.assign({}, state, {
        isAppInitializing: true
      });
    }

    default: {
      return state;
    }
  }
};

const customerHistoryState = {
  isLoading: false,
  all: {},
  sessionHistory: {},
  allRequests: [],
  sessionHistoryRequests: []
};

const customerHistoryReducer = (state = customerHistoryState, action) => {
  switch (action.type) {
    case actionTypes.getCustomerBookingHistory.request: {
      return Object.assign({}, state, {
        isLoading: true,
        allRequests: [...state.allRequests, action.payload]
      });
    }

    case actionTypes.getCustomerBookingHistory.success: {
      const { data } = action.payload;
      const { bookingId } = action.requestParams;
      return Object.assign({}, state, {
        isLoading: false,
        all: Object.assign({}, state.all, {
          [bookingId]: data
        })
      });
    }

    case actionTypes.getCustomerSessionAvailableList.request: {
      return Object.assign({}, state, {
        isLoading: false,
        sessionHistoryRequests: [...state.sessionHistoryRequests, action.payload]
      });
    }

    case actionTypes.getCustomerSessionAvailableList.success: {
      const { data } = action.payload;
      const { customerId } = action.requestParams;

      return Object.assign({}, state, {
        isLoading: false,
        sessionHistory: Object.assign({}, state.sessionHistory, {
          [customerId]: data
        })
      });
    }

    default: {
      return state;
    }
  }
};

const activityState = {
  isLoading: false,
  all: []
};

const activityReducer = (state = activityState, action) => {
  switch (action.type) {
    case actionTypes.getActivityList.request: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }
    case actionTypes.getActivityList.success: {
      const result = action.payload.data.resultList;
      if (!result) break;
      return Object.assign({}, state, {
        isLoading: false,
        all: [...state.all, ...result]
      });
    }
    default: {
      return state;
    }
  }
};

const participantsState = {
  isLoading: false,
  all: {},
  onGoingPresenceRequests: {}
};

const participantReducer = (state = participantsState, action) => {
  switch (action.type) {
    case actionTypes.getBookingCompleteParticipantList.request: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }
    case actionTypes.getBookingCompleteParticipantList.success: {
      const { onGoingPresenceRequests } = state;
      const { bookingId } = action.requestParams;
      const onGoingPresenceRequestsByBooking = onGoingPresenceRequests[bookingId];
      const resultParticipants = action.payload.data;

      if (onGoingPresenceRequestsByBooking) {
        const patientsBooking = resultParticipants.map(participant => {
          const onGoingPresenceRequest = onGoingPresenceRequestsByBooking.find(
            onGoingPresenceRequest => onGoingPresenceRequest.customerId === participant.customerId
          );
          if (!onGoingPresenceRequest) {
            return participant;
          }

          return Object.assign({}, participant, {
            present: onGoingPresenceRequest.present
          });
        });

        return Object.assign({}, state, {
          isLoading: false,
          all: Object.assign({}, state.all, {
            [action.requestParams.bookingId]: patientsBooking
          })
        });
      } else {
        return Object.assign({}, state, {
          isLoading: false,
          all: Object.assign({}, state.all, {
            [action.requestParams.bookingId]: resultParticipants
          })
        });
      }
    }

    case actionTypes.putMarkCustomerPresence.request: {
      const { bookingId, customerId, value } = action.payload;
      const allBookings = state.all;
      const participants = allBookings[bookingId];
      const participant = participants.find(participant => participant.customerId === customerId);
      const newParticipant = Object.assign({}, participant, {
        present: value
      });

      const onGoingPresenceRequests = state.onGoingPresenceRequests;
      const onGoingPresenceRequestsByBooking = onGoingPresenceRequests[bookingId] || [];

      return Object.assign({}, state, {
        all: Object.assign({}, state.all, {
          [bookingId]: uniqBy([newParticipant, ...participants], participant => participant.customerId)
        }),
        onGoingPresenceRequests: Object.assign({}, state.onGoingPresenceRequests, {
          [bookingId]: uniqBy(
            [{ customerId, present: value }, ...onGoingPresenceRequestsByBooking],
            onGoingPresenceRequest => onGoingPresenceRequest.customerId
          )
        })
      });
    }

    case actionTypes.putMarkCustomerPresence.success: {
      const { bookingId, customerId } = action.requestParams;
      const onGoingPresenceRequests = state.onGoingPresenceRequests;
      const onGoingPresenceRequestsByBooking = onGoingPresenceRequests[bookingId];
      return Object.assign({}, state, {
        onGoingPresenceRequests: Object.assign({}, state.onGoingPresenceRequests, {
          [bookingId]: onGoingPresenceRequestsByBooking.filter(
            onGoingPresenceRequest => onGoingPresenceRequest.customerId !== customerId
          )
        })
      });
    }

    default: {
      return state;
    }
  }
};

const contactState = {
  isLoading: false,
  all: []
};

const contactReducer = (state = contactState, action) => {
  switch (action.type) {
    case actionTypes.getContactTypeList.request: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }
    case actionTypes.getContactTypeList.success: {
      const result = action.payload.data;
      if (!result) break;
      return Object.assign({}, state, {
        isLoading: false,
        all: [...state.all, ...result]
      });
    }
    default: {
      return state;
    }
  }
};

const coachState = {
  isLoading: false,
  all: []
};

const coachReducer = (state = coachState, action) => {
  switch (action.type) {
    case actionTypes.getCoachList.request: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }
    case actionTypes.getCoachList.success: {
      const result = action.payload.data.resultList;
      if (!result) break;
      return Object.assign({}, state, {
        isLoading: false,
        all: [...state.all, ...result]
      });
    }
    default: {
      return state;
    }
  }
};

const bookingState = {
  isLoading: false,
  all: []
};
const bookingReducer = (state = bookingState, action) => {
  switch (action.type) {
    case actionTypes.getBookingList.request: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }
    case actionTypes.getBookingList.success: {
      return Object.assign({}, state, {
        isLoading: false,
        all: uniqBy([...action.payload.data, ...state.all], "id")
      });
    }

    default: {
      return state;
    }
  }
};

const roomState = {
  all: []
};
const roomReducer = (state = roomState, action) => {
  switch (action.type) {
    case actionTypes.getRoomList.request: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }
    case actionTypes.getRoomList.success: {
      return Object.assign({}, state, {
        isLoading: false,
        all: [...state.all, ...action.payload.data.resultList]
      });
    }
    default: {
      return state;
    }
  }
};

const supplierState = {
  isLoading: false,
  all: []
};
const supplierReducer = (state = supplierState, action) => {
  switch (action.type) {
    case actionTypes.getSupplierList.request: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }
    case actionTypes.getSupplierList.success: {
      return Object.assign({}, state, {
        isLoading: false,
        all: action.payload.data.resultList.filter(supplier => supplier.name !== "EPISOD")
      });
    }
    default: {
      return state;
    }
  }
};

export const reducers = {
  app: appReducer,
  room: roomReducer,
  booking: bookingReducer,
  contact: contactReducer,
  supplier: supplierReducer,
  coach: coachReducer,
  participant: participantReducer,
  customerHistory: customerHistoryReducer,
  activity: activityReducer
};
