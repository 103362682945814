import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";
import Flex from "app/components/Flex";
import Spinner from "app/components/Spinner";
import ItemParticipant from "tabletApp/tabletComponents/ItemParticipant";
import ModalParticipant from "tabletApp/tabletComponents/ModalParticipant";

import { getPosition } from "../../helper";
import classes from "./ListParticipant.module.css";
import { actions } from "../../../redux";

const sortNameByAlphabet = (a, b) => a.customerInformation.surname.localeCompare(b.customerInformation.surname);

const mapStateToProps = (state, ownProps) => ({
  isLoadingParticipants: state.participant.isLoading,
  participants: state.participant.all[ownProps.selectedBooking],
  booking: state.booking.all.find(booking => booking.id === ownProps.selectedBooking),
  contactTypes: state.contact.all,
  allRooms: state.room.all
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  dispatch
});

const CancelToken = axios.CancelToken;
class ListParticipant extends React.Component {
  static defaultProps = {
    isLoadingParticipants: true,
    selectedBooking: null,
    participants: []
  };

  interval = null;
  timerClearAlert = null;

  state = {
    isAlphabetSort: false,
    isShowAlert: false,
    isShowModal: false,
    selectedParticipant: null
  };

  componentDidMount() {
    this.setIntervalFetchParticipants();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    const {
      booking,
      participants,
      getCustomerBookingHistoryRequest,
      cancelGetCustomerBookingHistoryRequest,
      cancelGetCustomerSessionAvailableListRequest
    } = this.props;

    if (prevProps.booking.id !== booking.id || prevProps.participants.length !== participants.length) {
      getCustomerBookingHistoryRequest({
        customerId: participants.map(participant => participant.customerId),
        bookingId: booking.id,
        source: CancelToken.source()
      });
    }

    if (prevProps.booking.id !== booking.id) {
      clearInterval(this.interval);

      /**
       * cancel Fetch with booking ID
       */
      cancelGetCustomerBookingHistoryRequest();
      cancelGetCustomerSessionAvailableListRequest();
      this.setIntervalFetchParticipants();
      this.setState({
        isShowAlert: false,
        isShowModal: false,
        selectedParticipant: null
      });
    }
  }

  setIntervalFetchParticipants = () => {
    const TIME_INTERVAL = 60000;
    const { booking, getBookingCompleteParticipantListRequest } = this.props;
    if (booking) {
      this.interval = setInterval(() => {
        getBookingCompleteParticipantListRequest({ bookingId: booking.id });
      }, TIME_INTERVAL);
    }
  };

  handleShowModal = (isShowModal, participant, index) => {
    this.setState({ isShowModal, selectedParticipant: { ...participant, index } });
  };

  showAlertSessionCheckinEnd() {
    this.setState(
      {
        isShowAlert: true
      },
      () => {
        this.clearAlertSessionCheckinEnd();
      }
    );
  }

  clearAlertSessionCheckinEnd() {
    const CLEAR_TIME = 3000;
    this.timerClearAlert = setTimeout(() => {
      this.setState({
        isShowAlert: false
      });
    }, CLEAR_TIME);
  }

  handleMarkPresence = participant => {
    const { putMarkCustomerPresenceRequest, selectedBooking, booking } = this.props;
    const isBookingEnd = moment().isAfter(booking.endDate);
    if (isBookingEnd) {
      this.showAlertSessionCheckinEnd();
    } else {
      putMarkCustomerPresenceRequest({
        bookingId: selectedBooking,
        customerId: participant.customerId,
        value: !participant.present
      });
    }
  };

  renderParticipant = (participant, index) => {
    const { contactTypes, booking, allRooms } = this.props;
    return (
      <ItemParticipant
        key={`${participant.customerInformation.name}__${index}`}
        participant={participant}
        index={index}
        booking={booking}
        onPress={() => this.handleMarkPresence(participant)}
        onLongPress={() => this.handleShowModal(true, participant, index)}
        contactTypes={contactTypes}
        allRooms={allRooms}
      />
    );
  };

  handleClickSortPostion = () => {
    this.setState({
      isAlphabetSort: false
    });
  };

  handleClickSortAlphabet = () => {
    this.setState({
      isAlphabetSort: true
    });
  };

  render() {
    const { isShowModal, selectedParticipant } = this.state;
    const { isLoadingParticipants, participants, booking, contactTypes } = this.props;
    if (participants.length === 0 && isLoadingParticipants) {
      return (
        <div className={classes.loading}>
          <Spinner />
        </div>
      );
    }

    const notCheckinParticipants = participants
      .filter(participant => !participant.present)
      .sort((a, b) => {
        if (!a.personalEquipmentInformation || !b.personalEquipmentInformation) {
          return a - b;
        }

        if (a.personalEquipmentInformation.equipmentName && b.personalEquipmentInformation.equipmentName) {
          return (
            getPosition(a.personalEquipmentInformation.equipmentName) -
            getPosition(b.personalEquipmentInformation.equipmentName)
          );
        }
        return a - b;
      });

    const checkinParticipants = participants
      .filter(participant => participant.present)
      .sort((a, b) => {
        if (!a.personalEquipmentInformation || !b.personalEquipmentInformation) {
          return a - b;
        }

        if (a.personalEquipmentInformation.equipmentName && b.personalEquipmentInformation.equipmentName) {
          return (
            getPosition(a.personalEquipmentInformation.equipmentName) -
            getPosition(b.personalEquipmentInformation.equipmentName)
          );
        }
        return a - b;
      });

    return (
      <div className={classes.root}>
        {(notCheckinParticipants.length !== 0 || checkinParticipants.length !== 0) && (
          <div className={classes.sectionSort}>
            <button className={classes.sectionSortButton} onClick={this.handleClickSortPostion}>
              {"0 < 9"}
            </button>
            <button className={classes.sectionSortButton} onClick={this.handleClickSortAlphabet}>
              {"a < z"}
            </button>
          </div>
        )}
        {this.state.isShowAlert && <div className={classes.modalAlert}>Le check-in de cette session est fini</div>}
        {notCheckinParticipants.length === 0 && checkinParticipants.length === 0 && (
          <Flex direction="row" center middle className={classes.layoutEmpty}>
            <h1 className={classes.title}>Pas de participants à cette session</h1>
          </Flex>
        )}
        {isShowModal && selectedParticipant && (
          <ModalParticipant
            contactTypes={contactTypes}
            participant={selectedParticipant}
            booking={booking}
            onClick={() => this.handleShowModal(false)}
          />
        )}
        {notCheckinParticipants.length > 0 && (
          <Flex>
            <h1 className={classes.title}>Absent ({notCheckinParticipants.length})</h1>
            <Flex direction="row" wrap>
              {this.state.isAlphabetSort
                ? notCheckinParticipants.sort(sortNameByAlphabet).map(this.renderParticipant)
                : notCheckinParticipants.map(this.renderParticipant)}
            </Flex>
          </Flex>
        )}
        {checkinParticipants.length > 0 && (
          <Flex>
            <h1 className={classes.title}>Présent ({checkinParticipants.length})</h1>
            <Flex direction="row" wrap>
              {this.state.isAlphabetSort
                ? checkinParticipants.sort(sortNameByAlphabet).map(this.renderParticipant)
                : checkinParticipants.map(this.renderParticipant)}
            </Flex>
          </Flex>
        )}
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListParticipant);
