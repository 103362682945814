import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import classes from "./Spinner.module.css";

export const Spinner = ({ absolute = false, singleColor = false, small = false }) => (
  <div
    className={classnames(classes.spinner, {
      [classes.spinner__absolute]: absolute
    })}
  >
    <svg
      className={classnames(classes.loader__circular, {
        [classes.loader__small]: small
      })}
      viewBox="25 25 50 50"
    >
      <circle
        className={classnames(classes.loader__path, {
          [classes.loader__singleColor]: singleColor
        })}
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
);

Spinner.propTypes = {
  absolute: PropTypes.bool,
  singleColor: PropTypes.bool,
  small: PropTypes.bool
};

export default Spinner;
