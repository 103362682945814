import PropTypes from "prop-types";
import React from "react";
import Flex from "app/components/Flex";

import classes from "./ChooseStudioHeader.module.css";

const ChooseStudioHeader = ({ selectedHub, selectedStudio, isLoading }) => (
  <Flex middle direction="column" className={classes.chooseHeader}>
    {!isLoading &&
      selectedHub &&
      !selectedStudio && (
        <h1 key="header_menu_1" className={classes.chooseHeaderTitle}>
          HUB {selectedHub.name}
        </h1>
      )}

    {!isLoading &&
      selectedStudio && (
        <h1 key="header_menu_1" className={classes.chooseHeaderTitle}>
          STUDIO {selectedStudio.name}
        </h1>
      )}

    {!isLoading &&
      !selectedHub &&
      !selectedStudio && (
        <h1 key="header_menu_2" className={classes.chooseHeaderTitle}>
          LES HUBS
        </h1>
      )}
  </Flex>
);

ChooseStudioHeader.propTypes = {
  selectedHub: PropTypes.object,
  selectedStudio: PropTypes.object,
  isLoading: PropTypes.bool
};

export default ChooseStudioHeader;
