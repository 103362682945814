import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../redux/";
import config from "config";
import { getIsBourse, getIsNation, getCurrentTime, getIsPigalle } from "../../helpers";

const REGEX_ATHLETIC = /Athletic/;
const REGEX_LIVE = /Live/;

function getParticipants({ booking, participants = [], activities, clubId }) {
  if (!booking) {
    return false;
  }

  if (participants.length === 0) {
    return [];
  }

  const template = config.templateGrid[booking.templateKey];
  const isBootcamp = !!template;
  const isBourse = getIsBourse(clubId);
  const isNation = getIsNation(clubId);
  const isPigalle = getIsPigalle(clubId);

  // Athletic training and Live does not have templateKey so match name of activity
  const activityId = booking.activityIdList && booking.activityIdList[0];
  const activityCurrent = activities.find(activity => parseInt(activity.id, 10) === parseInt(activityId, 10));
  const activityName = activityCurrent && activityCurrent.name;
  const isAthletic = activityName && activityName.match(REGEX_ATHLETIC);
  const isLive = activityName && activityName.match(REGEX_LIVE);

  /**
   * Athletic layout does not require seats order.
   */
  if ((isAthletic || isLive) && isPigalle === false) {

    return [...Array(booking.nbPlace)].map((x, index) => participants[index]);

    /**
     * Bootcamp at Bourse has 14 each type of exercise
     */
  }
  else if (isAthletic && isPigalle ) {
    console.log(isPigalle);
    console.log(participants);
    let participantTemp = [...Array(40)].map(
      (x, index) =>
        participants.find(participant => participant.personalEquipmentInformation.equipmentName === `n°${index + 1}`) ||
        {}
    );

    let participantsDatas = [];
    participantTemp.forEach((item, index) => {
      if (item.bookingId > 0) {
        participantsDatas[index] = item;
      }
    });

    return participantsDatas;
  }
  else if (isBootcamp && isBourse) {
    const firstEquipement = [...Array(14)].map((x, index) => {
      return (
        participants.find(
          participant =>
            participant.personalEquipmentInformation.equipmentName === `n°${index + 1}` &&
            participant.personalEquipmentInformation.equipmentId.startsWith(template.equipments[0])
        ) || {}
      );
    });
    const secondEquipement = [...Array(14)].map(
      (x, index) =>
        participants.find(
          participant =>
            participant.personalEquipmentInformation.equipmentName === `n°${index + 1}` &&
            participant.personalEquipmentInformation.equipmentId.startsWith(template.equipments[1])
        ) || {}
    );
    return [...firstEquipement, ...secondEquipement];

    /**
     * Bootcamp at Nation has 10 each type of exercise
     */
  } else if (isBootcamp && isNation) {
    const firstEquipement = [...Array(10)].map((x, index) => {
      return (
        participants.find(
          participant =>
            participant.personalEquipmentInformation.equipmentName === `n°${index + 1}` &&
            participant.personalEquipmentInformation.equipmentId.startsWith(template.equipments[0])
        ) || {}
      );
    });
    const secondEquipement = [...Array(10)].map(
      (x, index) =>
        participants.find(
          participant =>
            participant.personalEquipmentInformation.equipmentName === `n°${index + 1}` &&
            participant.personalEquipmentInformation.equipmentId.startsWith(template.equipments[1])
        ) || {}
    );
    return [...firstEquipement, ...secondEquipement];

    /**
     * Bootcamp at other hubs has 15 each type of exercise
     */
  } else if (isBootcamp) {
    const firstEquipement = [...Array(15)].map(
      (x, index) =>
        participants.find(
          participant =>
            participant.personalEquipmentInformation.equipmentName === `n°${index + 1}` &&
            participant.personalEquipmentInformation.equipmentId.startsWith(template.equipments[0])
        ) || {}
    );
    const secondEquipement = [...Array(15)].map(
      (x, index) =>
        participants.find(
          participant =>
            participant.personalEquipmentInformation.equipmentName === `n°${index + 1}` &&
            participant.personalEquipmentInformation.equipmentId.startsWith(template.equipments[1])
        ) || {}
    );
    return [...firstEquipement, ...secondEquipement];

    /**
     * Other activity at other hubs has normal layout
     */
  }
  else {

    let participantTemp = [...Array(40)].map(
      (x, index) =>
        participants.find(participant => participant.personalEquipmentInformation.equipmentName === `n°${index + 1}`) ||
        {}
    );

    let participantsDatas = [];
    participantTemp.forEach((item, index) => {
      if(item.bookingId > 0 ){
        participantsDatas[index] = item;
      }
    });

    return  participantsDatas;
    //participantTemp;
    //return [...participants];
  }
}

const mapStateToProps = (state, props) => {
  const regexStartTime = new RegExp("[?&]startTime=([^&#]*)");

  const queryString = props.location.search;
  const matchStartTime = queryString.match(regexStartTime);
  const startTime = matchStartTime ? matchStartTime[1] : getCurrentTime(state.booking.all);

  //const currentTime = getCurrentTime(state.booking.all);
  const clubId = props.match.params.clubId && parseInt(props.match.params.clubId, 10);
  const roomId = props.match.params.roomId && parseInt(props.match.params.roomId, 10);
  const activities = state.activity.all;

  // TODO: Use reselect to minimize calculation
  const booking = state.booking.all.find(booking => {
    return booking.roomIdList[0] === roomId && booking.beginDate === startTime;
  });

  const participants = booking ? state.participant.all[booking.id] : null;
  const sortedParticipants = getParticipants({ booking, participants, clubId, activities });

  return {
    roomId,
    clubId,
    suppliers: state.supplier.all,
    room: state.room.all.find(room => room.id === roomId),
    booking,
    participants: sortedParticipants,
    coaches: state.coach.all,
    activities: state.activity.all,
    contactTypes: state.contact.all,

    isBookingLoading: state.booking.isLoading,
    isParticipantsLoading: state.participant.isLoading,
    isCoachLoading: state.coach.isLoading,
    isActivityLoading: state.activity.isLoading,
    isSupplierLoading: state.supplier.isLoading,
    isRoomLoading: state.room.isLoading
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  dispatch
});

const withCheckinPageContainer = Component => {
  function CheckinPageContainer(props) {
    const {
      participants,
      booking,
      roomId,
      getBookingListRequest,
      getBookingCompleteParticipantListRequest,
      isBookingLoading,
      isParticipantsLoading,
      isCoachLoading,
      isActivityLoading,
      isSupplierLoading,
      isRoomLoading
    } = props;

    // Remove isParticipantsLoading from isLoading because we refresh participants every 60 seconds
    const localProps = {
      isLoading: isSupplierLoading || isRoomLoading || isBookingLoading || isActivityLoading || isCoachLoading
    };

    useEffect(() => {
      if (!booking && !isBookingLoading) {
        getBookingListRequest({ roomId });
      }

      let fetchBookingInterval;

      if (booking && participants.length === 0 && !isParticipantsLoading) {
        getBookingCompleteParticipantListRequest({ bookingId: booking.id });
      }

      if (booking && participants.length > 0) {
        fetchBookingInterval = setInterval(() => {
          getBookingCompleteParticipantListRequest({ bookingId: booking.id });
        }, 60000);
      }

      //refreshInterval
      let reloadTime = 1000 * 60 * 60;
      setInterval(() => {
        window.location.reload(false);
        //console.log('REFRESH');
      }, reloadTime);

      // https://reactjs.org/docs/hooks-effect.html#example-using-hooks-1
      return () => {
        if (fetchBookingInterval) {
          clearInterval(fetchBookingInterval);
        }
      };
    });
    return <Component {...props} {...localProps} />;
  }

  return connect(mapStateToProps, mapDispatchToProps)(CheckinPageContainer);
};

export default withCheckinPageContainer;
