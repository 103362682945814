import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../redux/";
import IconButton from "app/components/IconButton";
import ItemHeader from "tabletApp/tabletComponents/ItemHeader";

import classes from "./Header.module.css";

const mapStateToProps = state => ({
  allRooms: state.room.all
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  dispatch
});

class Header extends React.PureComponent {
  list = React.createRef();
  target = React.createRef();

  handleSetLeft() {
    if (this.list.current.scroll) {
      if (this.target && this.target.current) {
        this.list.current.scroll({
          top: 0, // could be negative value
          left: this.target.current.offsetLeft - this.target.current.offsetWidth,
          behavior: "smooth"
        });
      }
    } else {
      this.list.current.scrollLeft = this.target.current.offsetLeft - this.target.current.offsetWidth;
    }
  }

  componentDidMount() {
    this.handleSetLeft();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedBooking !== this.props.selectedBooking) {
      this.handleSetLeft();
    }
  }

  render() {
    const { handleToggleSidebar, handleClickBooking, bookings, selectedBooking } = this.props;

    return (
      <header className={classes.root}>
        <div className={classes.iconWrapper}>
          <IconButton type="list" size={1.4} onClick={handleToggleSidebar} />
        </div>
        <div className={classes.list} ref={this.list}>
          {bookings.map(booking => {
            const isSelected = selectedBooking === booking.id;
            return (
              <ItemHeader
                key={booking.id}
                refToTarget={isSelected ? this.target : () => {}}
                selected={isSelected}
                booking={booking}
                onClick={() => handleClickBooking(booking)}
              />
            );
          })}
        </div>
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
