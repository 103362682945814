import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import Flex from "app/components/Flex";

import classes from "./Layout.module.css";

const Layout = ({ children, background }) => (
  <Flex direction="column" className={background ? classnames(classes.layout, classes.background) : classes.layout}>
    {children}
  </Flex>
);

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
