import React, { useState } from "react";
import TabletPage from "./TabletPage";

const TabletPageContainer = ({ children }) => {
  const [selectedHub, setSelectedHub] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const props = {
    selectedHub,
    setSelectedHub,
    selectedBooking,
    setSelectedBooking
  };
  return <React.Fragment>{children(props)}</React.Fragment>;
};

const withContainer = Component => () => {
  return <TabletPageContainer>{props => <Component {...props} />}</TabletPageContainer>;
};

export default withContainer(TabletPage);
