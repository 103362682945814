import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Spinner from "app/components/Spinner";
import Layout from "app/components/Layout";
import CheckinHeader from "app/components/CheckinHeader";
import ParticipantList from "app/components/ParticipantList";
import withCheckinPageContainer from "../CheckinPage/CheckinPageContainer";
import { getIsBourse, getIsNation, getIsPigalle } from "../../helpers";

const CheckinPage = ({
  history,
  clubId,
  roomId,
  room,
  activities,
  coaches,
  booking,
  participants,
  onParticipantCheck,
  contactTypes,
  suppliers,
  bookingHistory,
  isLoading
}) => (
  <div>
    {isLoading ? (
      <Layout background>
        <Spinner absolute />
      </Layout>
    ) : (
      <Layout>
        {booking ? (
          <CheckinHeader
            suppliers={suppliers}
            clubId={clubId}
            roomId={roomId}
            room={room}
            activities={activities}
            coaches={coaches}
            booking={booking}
            history={history}
          />
        ) : (
          <CheckinHeader noInfo history={history} clubId={clubId} />
        )}

        {participants && participants.length > 0 ? (
          <ParticipantList
            bookingHistory={bookingHistory}
            participants={participants}
            booking={booking}
            onParticipantCheck={onParticipantCheck}
            contactTypes={contactTypes}
            isBourse={getIsBourse(clubId)}
            isNation={getIsNation(clubId)}
            isPigalle={getIsPigalle(clubId)}
            isBootcamp={room && room.name === "Bootcamp"}
          />
        ) : (
          ""
        )}
      </Layout>
    )}
  </div>
);

CheckinPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  clubId: PropTypes.string,
  roomId: PropTypes.string,
  onParticipantCheck: PropTypes.func,
  rooms: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  activitys: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  coaches: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  booking: ImmutablePropTypes.map,
  bookingParticipants: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  contactTypes: ImmutablePropTypes.listOf(ImmutablePropTypes.map)
};

export default withCheckinPageContainer(CheckinPage);
