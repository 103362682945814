import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import moment from "moment";
import Flex from "app/components/Flex";
import IconButton from "app/components/IconButton";

import classes from "./CheckinHeader.module.css";

const CheckinHeader = ({ clubId, roomId, room, activities, coaches, booking, suppliers, history, noInfo }) => {
  const onClickBackButton = () => {
    const isStartTimeSet = history.location.search.includes("startTime");
    isStartTimeSet
      ? history.push(`/checkin?clubId=${clubId}&roomId=${roomId}`)
      : history.push(`/checkin?clubId=${clubId}`);
  };

  if (noInfo) {
    return (
      <Flex direction="row" middle space="between" className={classes.checkinHeader}>
        <Flex size={5}>
          <Flex direction="row" className={classes.checkinHeader__left}>
            <IconButton onClick={onClickBackButton} size={2} />
            <Flex size={1} direction="column" className={classes.checkinHeader__left__info}>
              <p>Aucune session</p>
            </Flex>
          </Flex>
        </Flex>
        <Flex size={2} direction="column" middle className={classes.checkinHeader__right} />
      </Flex>
    );
  }
  return (
    <div>
      <Flex direction="row" middle space="between" className={classes.checkinHeader}>
        <Flex size={3}>
          <Flex direction="row" className={classes.checkinHeader__left}>
            <IconButton onClick={onClickBackButton} size={2} />
            <Flex size={1} direction="column" className={classes.checkinHeader__left__info}>
              <span>
                {suppliers &&
                suppliers.length > 0 &&
                suppliers.find(supplier => parseInt(supplier.id, 10) === parseInt(clubId, 10))
                  ? suppliers.find(supplier => parseInt(supplier.id, 10) === parseInt(clubId, 10)).name
                  : "Studio"}
              </span>
              <span className={classes.checkinHeader__studio__name}>{room ? room.name.replace("CLI - ", "") : ""}</span>
            </Flex>
          </Flex>
        </Flex>
        <Flex size={2} direction="column" middle className={classes.checkinHeader__right}>
          <div>
            {booking.activityIdList.length > 0 && activities.find(activity => activity.id === booking.activityIdList[0])
              ? activities.find(activity => activity.id === booking.activityIdList[0]).name
              : ""}
          </div>
          <div>{moment(booking.beginDate).format("HH[h]mm")}</div>
          <div>
            <span>avec </span>
            <span>
              {booking.coachIdList.length > 0 && coaches.find(coach => coach.id === booking.coachIdList[0])
                ? coaches.find(coach => coach.id === booking.coachIdList[0]).description ||
                  coaches.find(coach => coach.id === booking.coachIdList[0]).surname
                : ""}
            </span>
          </div>
        </Flex>
      </Flex>
    </div>
  );
};

CheckinHeader.propTypes = {
  roomId: PropTypes.string.isRequired,
  booking: ImmutablePropTypes.map.isRequired,
  rooms: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
  activities: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
  coaches: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired
};

export default CheckinHeader;
