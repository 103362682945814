import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Flex from "app/components/Flex";
import ParticipantItem from "app/components/ParticipantItem";
import classes from "./ParticipantList.module.css";

const getRowNumber = participantSize => {
  const baseRows = Math.floor(participantSize / 10);
  return participantSize % 10 === 0 ? baseRows : baseRows + 1;
};



const ParticipantList = ({
  participants,
  onParticipantCheck,
  contactTypes,
  isBootcamp,
  isBourse,
  isPigalle,
  isNation,
  booking,
  bookingHistory
}) => (
  <React.Fragment>
    {isBootcamp &&
      isBourse && (
        <Flex className={classes.participantList} direction="row" size={1} wrap>
          <div className={classes.participantListSection}>
            <h1 className={classes.participantListTitle}>LES FITBENCH</h1>
          </div>
          {participants
            .slice(0, 14)
            .filter(participant => participant)
            .map((participant, index) => (
              <ParticipantItem
                key={index}
                position={index + 1}
                numberOfRows={4}
                isFirstTime={false}
                booking={booking}
                participant={participant}
                onParticipantCheck={onParticipantCheck}
                contactType={contactTypes.find(
                  contactType =>
                    participant &&
                    participant.customerInformation &&
                    contactType.id === participant.customerInformation.contacttypeId
                )}
              />
            ))}
          <div className={classes.participantListSection}>
            <h1 className={classes.participantListTitle}>LES TAPIS</h1>
          </div>
          {participants.slice(14, 28).map((participant, index) => (
            <ParticipantItem
              key={index}
              position={index + 1}
              numberOfRows={4}
              booking={booking}
              // isFirstTime={bookingHistory.get(participant.get("customerId"))}
              participant={participant}
              onParticipantCheck={onParticipantCheck}
              contactType={contactTypes.find(
                contactType =>
                  participant &&
                  participant.customerInformation &&
                  contactType.id === participant.customerInformation.contacttypeId
              )}
            />
          ))}
        </Flex>
      )}
    {isBootcamp &&
      isNation && (
        <Flex className={classes.participantList} direction="column" size={1} wrap>
          <Flex className={classes.participantList} direction="row" size={1} wrap>
            <div className={classes.participantListSection}>
              <h1 className={classes.participantListTitle}>LES FITBENCH</h1>
            </div>
            {participants
              .slice(0, 10)
              .filter(participant => participant)
              .map((participant, index) => (
                <ParticipantItem
                  key={index}
                  position={index + 1}
                  numberOfRows={4}
                  booking={booking}
                  participant={participant}
                  onParticipantCheck={onParticipantCheck}
                  contactType={contactTypes.find(
                    contactType =>
                      participant &&
                      participant.customerInformation &&
                      contactType.id === participant.customerInformation.contacttypeId
                  )}
                />
              ))}
          </Flex>
          <Flex className={classes.participantList} direction="row" size={1} wrap>
            <div className={classes.participantListSection}>
              <h1 className={classes.participantListTitle}>LES TAPIS</h1>
            </div>
            {participants
              .slice(10, 20)
              .map((participant, index) => (
                <ParticipantItem
                  key={index}
                  position={index + 1}
                  numberOfRows={4}
                  booking={booking}
                  participant={participant}
                  onParticipantCheck={onParticipantCheck}
                  contactType={contactTypes.find(
                    contactType =>
                      participant &&
                      participant.customerInformation &&
                      contactType.id === participant.customerInformation.contacttypeId
                  )}
                />
              ))}
          </Flex>
        </Flex>
      )}
    {isBootcamp &&
      !isBourse &&
      !isNation && (
        <Flex className={classes.participantList} direction="row" size={1} wrap>
          <div className={classes.participantListSection}>
            <h1 className={classes.participantListTitle}>LES FITBENCH</h1>
          </div>
          {participants
            .slice(0, 15)
            .filter(participant => participant)
            .map((participant, index) => (
              <ParticipantItem
                key={index}
                position={index + 1}
                numberOfRows={4}
                booking={booking}
                // isFirstTime={bookingHistory.get(participant.get("customerId"))}
                participant={participant}
                onParticipantCheck={onParticipantCheck}
                contactType={contactTypes.find(
                  contactType =>
                    participant &&
                    participant.customerInformation &&
                    contactType.id === participant.customerInformation.contacttypeId
                )}
              />
            ))}
          <div className={classes.participantListSection}>
            <h1 className={classes.participantListTitle}>LES TAPIS</h1>
          </div>
          {participants.slice(15, 30).map((participant, index) => (
            <ParticipantItem
              key={index}
              position={index + 1}
              numberOfRows={4}
              booking={booking}
              // isFirstTime={bookingHistory.get(participant.get("customerId"))}
              participant={participant}
              onParticipantCheck={onParticipantCheck}
              contactType={contactTypes.find(
                contactType =>
                  participant &&
                  participant.customerInformation &&
                  contactType.id === participant.customerInformation.contacttypeId
              )}
            />
          ))}
        </Flex>
      )}
    {!isBootcamp && (
      <Flex className={classes.participantList} direction="row" size={1} wrap>
        {participants.map((participant, index) => (
          <ParticipantItem
            key={index}
            position={index + 1}
            numberOfRows={getRowNumber(participants.length)}
            booking={booking}
            // isFirstTime={bookingHistory.get(participant.get("customerId"))}
            participant={participant}
            onParticipantCheck={onParticipantCheck}
            contactType={contactTypes.find(
              contactType =>
                participant &&
                participant.customerInformation &&
                contactType.id === participant.customerInformation.contacttypeId
            )}
          />
        ))}
      </Flex>
    )}
  </React.Fragment>
);

ParticipantList.propTypes = {
  onParticipantCheck: PropTypes.func,
  participants: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
  contactTypes: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired
};

export default ParticipantList;
