import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import sortBy from "lodash/sortBy";

import Header from "tabletApp/tabletComponents/Header";
import Sidebar from "tabletApp/tabletComponents/Sidebar";
import ListParticipant from "tabletApp/tabletComponents/ListParticipant";
import { actions } from "../../redux";
import { ReactComponent as Logo } from "./logo.svg";
import classes from "./TablePage.module.css";

const mapStateToProps = (state, ownProps) => {
  const rooms = state.room.all.filter(room => room.supplierId === ownProps.selectedHub);
  return {
    isAppInitializing: state.app.isAppInitializing,
    allSuppliers: sortBy(state.supplier.all, "name"),
    allBookingsSize: state.booking.all.length,
    bookings: state.booking.all.sort((a, b) => new Date(a.beginDate) - new Date(b.beginDate)).filter(booking => {
      return rooms.find(room => room.id === booking.roomIdList[0]);
    }),
    rooms
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(actions, dispatch),
  dispatch
});

class TabletPage extends React.PureComponent {
  interval = null;

  state = {
    isOpenSidebar: false,
    isNeedUpdate: false
  };

  static defaultProps = {
    allSuppliers: [],
    bookings: [],
    rooms: []
  };

  bookingFetchInterval = null;

  componentDidMount() {
    this.setInitialHub();
    this.setInitialBooking();

    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
  }

  componentDidUpdate(prevProps) {
    const { selectedHub, getBookingListRequest, rooms } = this.props;
    const isChangeSelectedHub = prevProps.selectedHub !== selectedHub;

    if (!this.state.isNeedUpdate && isChangeSelectedHub) {
      this.setState({ isNeedUpdate: true });
    }

    if (this.state.isNeedUpdate && rooms.length > 0) {
      getBookingListRequest({ supplierId: selectedHub, roomId: rooms.map(room => room.id) });
      clearInterval(this.bookingFetchInterval);
      this.bookingFetchInterval = setInterval(() => {
        getBookingListRequest({ supplierId: selectedHub, roomId: rooms.map(room => room.id) });
      }, 60000);
      this.setState({ isNeedUpdate: false });
    }

    // When there is not initial hub
    if (!prevProps.selectedHub) {
      this.setInitialHub();
    }

    if (!prevProps.selectedBooking) {
      this.setInitialBooking();
    }
  }

  componentWillUnmount() {
    clearInterval(this.bookingFetchInterval);
  }

  setInitialHub() {
    const { selectedHub, setSelectedHub, allSuppliers } = this.props;

    if (!selectedHub && allSuppliers && allSuppliers.length > 0) {
      setSelectedHub(allSuppliers[0].id);
    }
  }

  setInitialBooking() {
    const { setSelectedBooking, bookings } = this.props;
    if (bookings.length > 0) {
      setSelectedBooking(bookings[0].id);
      this.fetchParticipants(bookings[0]);
    }
  }

  handleToggleSidebar = isOpenSidebar => {
    if (typeof isOpenSidebar === undefined) {
      this.setState(prevState => ({
        isOpenSidebar: !prevState.isOpenSidebar
      }));
    } else {
      this.setState({
        isOpenSidebar: isOpenSidebar
      });
    }
  };

  fetchParticipants = booking => {
    const { getBookingCompleteParticipantListRequest } = this.props;
    getBookingCompleteParticipantListRequest({ bookingId: booking.id });
  };

  handleClickBooking = booking => {
    const { setSelectedBooking } = this.props;
    this.fetchParticipants(booking);
    setSelectedBooking(booking.id);
    this.handleToggleSidebar(false);
  };

  render() {
    const { isOpenSidebar } = this.state;
    const {
      isAppInitializing,
      selectedHub,
      setSelectedHub,
      selectedBooking,
      setSelectedBooking,
      bookings
    } = this.props;

    if (isAppInitializing) {
      return (
        <div className={classes.rootLoading}>
          <Logo />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        {isOpenSidebar ? (
          <Sidebar
            handleToggleSidebar={this.handleToggleSidebar}
            handleClickBooking={this.handleClickBooking}
            selectedHub={selectedHub}
            selectedBooking={selectedBooking}
            setSelectedHub={setSelectedHub}
            setSelectedBooking={setSelectedBooking}
            bookings={bookings}
          />
        ) : (
          <main className={classes.main}>
            <Header
              selectedBooking={selectedBooking}
              handleToggleSidebar={this.handleToggleSidebar}
              handleClickBooking={this.handleClickBooking}
              bookings={bookings}
            />
            <ListParticipant selectedBooking={selectedBooking} />
          </main>
        )}
      </div>
    );
  }
}

TabletPage.propTypes = {
  isAppInitializing: PropTypes.bool.isRequired,
  selectedHub: PropTypes.number,
  setSelectedHub: PropTypes.func,
  selectedBooking: PropTypes.number,
  setSelectedBooking: PropTypes.func,
  bookings: PropTypes.arrayOf(PropTypes.object)
};

export default connect(mapStateToProps, mapDispatchToProps)(TabletPage);
