import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import classes from "./IconButton.module.css";
import Back from "./navigate_before.svg";
import Close from "./close.svg";
import Check from "./check.svg";
import List from "./list.svg";
import ArrowDown from "./arrow-down.svg";

const getButtonIcon = type => {
  type = type.toLowerCase();
  switch (type) {
    case "back": {
      return Back;
    }

    case "check": {
      return Check;
    }

    case "list": {
      return List;
    }

    case "close": {
      return Close;
    }

    case "arrowdown": {
      return ArrowDown;
    }

    default: {
      return Back;
    }
  }
};

export const IconButton = ({ onClick: onBackSection, type = "Back", size = 1, className }) => (
  <span
    className={classnames(classes.icon, className)}
    onClick={onBackSection}
    style={{
      width: `${60 * size + 2}px`,
      height: `${60 * size + 2}px`
    }}
  >
    <img
      src={getButtonIcon(type)}
      alt={`icon-${type}`}
      style={{
        transform: `scale(${size * 1.5})`
      }}
      className={classes.iconImg}
    />
  </span>
);

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  size: PropTypes.number
};

export default IconButton;
