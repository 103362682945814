import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import moment from "moment-timezone";
import classes from "./ItemHeader.module.css";
import { getActivityName, getIsCheckinEndingSoon } from "../../helper";

const mapStateToProps = (state, ownProps) => {
  const { booking } = ownProps;
  const { activityIdList = [], coachIdList = [] } = booking;
  const { activity, coach, participant } = state;
  return {
    activity: activity.all.find(activity => activity.id === activityIdList[0]),
    coach: coach.all.find(coach => coach.id === coachIdList[0]),
    participants: participant.all[booking.id],
    numberCheckedinParticipants: participant.all[booking.id]
      ? participant.all[booking.id].filter(participant => participant.present).length
      : 0
  };
};

class ItemHeader extends React.PureComponent {
  static defaultProps = {
    activity: "",
    coach: "",
    participants: [],
    numberCheckedinParticipants: 0,
    selected: false
  };

  state = {
    initNumber: 0,
    isCheckinEndingSoon: false
  };

  checkSessionTimer = null;

  componentDidMount() {
    this.checkIsCheckinEndingSoon();
    this.checkSessionTimer = setInterval(() => {
      this.checkIsCheckinEndingSoon();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.checkSessionTimer);
  }

  checkIsCheckinEndingSoon = () => {
    const { booking } = this.props;
    const { beginDate } = booking;

    const isCheckinEndingSoon = getIsCheckinEndingSoon(beginDate);
    this.setState({
      isCheckinEndingSoon
    });
  };

  render() {
    const { onClick, selected, activity, coach, booking, numberCheckedinParticipants, refToTarget } = this.props;
    const coachname = coach.description || coach.surname || "-";
    return (
      <div
        className={classnames(classes.root, {
          [classes.selected]: selected
        })}
        ref={refToTarget}
        onClick={onClick}
      >
        <span className={classes.text}>{getActivityName(activity.name, { fullName: true })}</span>
        <span className={classnames(classes.text, classes.textCoachName)}>{coachname.slice(0, 10)}</span>
        <span className={classes.third}>
          <span className={classes.number}>
            {moment(booking.beginDate)
              .tz("Europe/Paris")
              .format("HH:mm")}
          </span>
          {this.state.isCheckinEndingSoon && <div className={classes.iconFire} />}
          <span key={numberCheckedinParticipants}>
            <span className={classes.number} key={numberCheckedinParticipants}>
              {booking.nbPlaceInUsed}
            </span>
            <span className={classes.text}>/</span>
            <span className={classes.number}>{booking.nbPlace}</span>
          </span>
        </span>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(ItemHeader);
