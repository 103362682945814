import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

import classes from "./Flex.module.css";

export const Flex = ({
  size,
  grow,
  className,
  children,
  direction,
  middle,
  center,
  end,
  wrap,
  space,
  right,
  strech,
  ...props
}) => (
  <div
    className={classnames(className, classes[`flex-${size}`], classes[`flex-grow-${grow}`], {
      [classes[`flex-${direction}`]]: direction,
      [classes.middle]: middle,
      [classes.center]: center,
      [classes.end]: end,
      [classes["flex-wrap"]]: wrap,
      [classes[`space-${space}`]]: space,
      [classes.right]: right,
      [classes.strech]: strech
    })}
    {...props}
  >
    {children}
  </div>
);

Flex.propTypes = {
  size: PropTypes.number,
  grow: PropTypes.number,
  direction: PropTypes.string,
  middle: PropTypes.bool,
  center: PropTypes.bool,
  end: PropTypes.bool,
  wrap: PropTypes.bool,
  right: PropTypes.bool,
  space: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

export default Flex;
